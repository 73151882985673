import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-daikin-unit-status',
  templateUrl: './daikin-unit-status.component.html',
  styleUrls: ['./daikin-unit-status.component.scss']
})
export class DaikinUnitStatusComponent implements OnInit, OnDestroy {
  Property = Property
  @Input() controllers: Controller[];
  @Input() forcePropIndex: number;
  updatedSub: Subscription;
  updated;

  constructor(private projectService: ProjectService) {}

  ngOnInit() {
    this.updatedSub = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
    })
  }

  ngOnDestroy () {
    if (this.updatedSub) {
      this.updatedSub.unsubscribe();
    }
  }

}
