<div class="flex-row-space-evenly margin-top30 modal-content-container">
  <div class="width90 flex-row">

    <div class="flex-column width15">
      <ion-list *ngIf="controllers.length > 1" class="width-150">
        <ion-list-header>{{'Controllers' | translate}}</ion-list-header>
        <ion-item
          button
          *ngFor="let controller of controllers"
          (click)="selectController(controller)"
          [ngClass]="{
            'ion-list-activated': selectedControllers[0].id === controller.id
          }"
        >
          {{ controller.name }}
        </ion-item>
      </ion-list>

      <div class="flex-column width100">
        <ion-list class="flex-row">
          <ion-list-header>{{ "Tags" | translate }}
            <ion-icon *ngIf="signedInUser.havePermission(SoftwarePermissionId.LocationTags)" class="margin-left15 clickable" name="create-outline" (click)="editControllerTags($event)"></ion-icon>
          </ion-list-header>
        </ion-list>
        <div *ngFor="let tag of location.locationTags.$values">
          {{tag.tag}}
        </div>
      </div>
    </div>

    <div class="flex-column width30">
      <div class="margin-bottom30">{{'STATUSES' | translate}}</div>

      <ng-container
      *ngIf="controllers | findProperty: isDoorOpened as doorProps"
    >
      <div *ngFor="let door of doorProps" class="flex-row-center-v">
          <div
            *ngIf="door | propIsActive; else doorClosed"
            title="{{'Door open' | translate}}"
            class="icon-door_opened_status font-size20"
          ></div>
          <ng-template #doorClosed>
            <div title="{{'Door closed' | translate}}" class="icon-door font-size20"></div>
          </ng-template>
          <div class="prop-title">{{ door.name | translate }}</div>
        </div>
    </ng-container>
    </div>

    <div class="flex-column width30">
      <div class="flex-column programming-box">
        <div class="margin-bottom30 uppercase">{{'Programming' | translate}}</div>

        <div *ngIf="(controllers | propExistInControllers: isACDoorGuestDenyFrom) && (controllers | propExistInControllers: isACDoorGuestDenyTo) && (controllers | canIntervalBeSetPipe : isOfflineTimer)"  class="flex-row-start-end">
          <ion-item  lines="none" class="item-background-color">
            <ion-checkbox
            class="margin-right5"
            [checked]="intervalForDoorsActive"
            (ionChange)="onSetIntervalClicked($event)">
          </ion-checkbox>
            {{'Set interval for denied entry' | translate}}
          </ion-item>
          <app-select-time
          *ngIf="intervalForDoorsActive"
          (setTime$)="procesTimeObject($event)"
          [controllers]="controllers">
          </app-select-time>
        </div>

        <app-boolean-input
        *ngIf="controllers | propExistInControllers : isACDoorOpenPushButton"
        [controllers]="controllers"
        [findPropFunction]="isACDoorOpenPushButton"
        >{{'Door open push button' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDoorOpenAnyCard"
        [controllers]="controllers"
        [findPropFunction]="isACDoorOpenAnyCard"
        >{{'Door open any card' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACAlwaysOpen"
      [controllers]="controllers"
      [findPropFunction]="isACAlwaysOpen"
      >{{'Is Always open' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDenyGuests"
      [controllers]="controllers"
      [findPropFunction]="isACDenyGuests"
      >{{'Deny Guests' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDenyStaff"
      [controllers]="controllers"
      [findPropFunction]="isACDenyStaff"
      >{{'Deny Staff' | translate}}</app-boolean-input>
      <app-boolean-input
      *ngIf="controllers | propExistInControllers : isACDenyAll"
      [controllers]="controllers"
      [findPropFunction]="isACDenyAll"
    >{{'Deny All' | translate}}</app-boolean-input>

    <ion-item lines="none" class="item-background-color">
      <ion-checkbox
        (ionChange)="changeControllerPooling($event)"
        justify="start"
        labelPlacement="end"
        class="margin-right5"
        [checked]="stopControllerPolling"
      > {{ "Stop controller polling" | translate }}</ion-checkbox>
    </ion-item>

    <ion-button
    class="margin5"
    color="light"
    (click)="syncHvac()"
    size="small"
    ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
    {{'Settings sync' | translate}}</ion-button>


      </div>
    </div>

    <div class="flex-column width22 margin-left20">

       <!-- ALARM BOX YELLOW  -->
       <div class="alarm-box-yellow">
         <div class="flex-row-center-v margin-bottom5 width100">
           <div class="icon-alert font-size20 yellow"></div>
           <div class="margin-left20 margin-top5"> {{'Warnings' | translate}}</div>
         </div>

         <div class="flex-row margin-left20" *ngIf="
         (controllers
           | findProperty: Property.isDoorOpenTooLongAlarm
           | propIsActive)">

       <ng-container *ngIf="
       selectedControllers
             | findProperty: Property.isDoorOpenTooLongAlarm as doorOpenTooLongProps
         ">
         <ng-container *ngFor="let doorOpenTooLongProp of doorOpenTooLongProps">
           <div *ngIf="doorOpenTooLongProp | propIsActive">
             <div class="prop-title">
               {{ getAlarmName(doorOpenTooLongProp) | translate }}
             </div>
           </div>
         </ng-container>
       </ng-container>

     </div>
       </div>
     </div>

  </div>

</div>
