import { Component, Input, Output, OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { forkJoin, Observable, Subscription, tap } from 'rxjs';
import { Card } from 'src/app/core/models/card/card.model';
import { Controller } from 'src/app/core/models/controller.model';
import { Permission } from 'src/app/core/models/permissions/permission.model';
import { User } from 'src/app/core/models/user/user.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRolesService } from 'src/app/modules/roles/services/http/api-roles.service';
import { RolesService } from 'src/app/modules/roles/services/roles.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';
import { UsersService } from 'src/app/modules/users/services/users.service';
import { LocationGroup } from '../../services/api-location-groups.service';
import { EditCardModalComponent } from '../../../modules/rooms/components/edit-card-modal/edit-card-modal.component';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { enterAnimation } from 'src/app/shared/animations/animations';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { LocationGroupsService } from '../../services/location-groups.service';
import { CardOnHolderService } from 'src/app/core/services/card-on-holder.service';
import { ApiProjectService, KeyOption } from 'src/app/modules/project/services/http/api-project.service';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { FiltersService } from '../../services/filters.service';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ControllerFilters } from 'src/app/core/models/view-filter.model';
import { PermissionType } from 'src/app/core/models/permissions/permission.enum';
import { CustomTranslatePipe } from '../../pipes/custom-translate.pipe';
import { Location } from 'src/app/core/models/project/location.model';
import { BleKeyQrCodeComponent } from '../ble-key-qr-code/ble-key-qr-code.component';

@Component({
  selector: 'app-room-modal-cards',
  templateUrl: './room-modal-cards.component.html',
  animations: [enterAnimation],
  styleUrls: ['./room-modal-cards.component.scss'],
})
export class RoomModalCardsComponent implements OnInit, OnDestroy/* , AfterViewInit  */ {
  @Input() location: Location;
  @Output() guestCardsUpdated: EventEmitter<Card[]> = new EventEmitter<[]>();

  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  guestCards: Card[] = [];
  roomSwap = false;
  openModal = false;
  controllers: Controller[];

  permissions: Permission[];
  permissionsSubscription: Subscription;

  accessControlIds: string[] = [];
  floorList$: Observable<LocationGroup[]> = this.locationGroupsService.getFloorList();

  targetRoomToMoveCard: Location;
  movingGuest: User;



  guestAddedSubscription: Subscription;

  cardOnHolder: boolean;
  cardOnHolderSubscription: Subscription;
  openCardOnReaderModal = false;
  cardOnReaderExist = false;
  // userBeingCreated = false;
  activePopup: string;
  userToShowInPopup: User;
  tablesReady = false;
  guestFreeSpace: number;

  signedInUser: User;
  swPermissions = SoftwarePermissionId;

  objectsFilterFull$ = this.filtersService.getObjectsFilterFull();
  selectedDesignation = '*/*/*/*';
  allObjects$: Observable<ProjectObject[]> = this.projectService.getObjects();

  guestLocationsThin$: Observable<Location[]> = this.apiProjectService.getAllGuestRoomLocations('slim');

  loading = false;
  backupCardExists: boolean;

  lastGuestCardOnHolder$ = this.cardsService.getLastGuestCardOnHolder()
  mainController: Controller;
  cardSyncActiveSubscription: Subscription;
  cardSyncActive = false;

  constructor(private modalController: ModalController,
              private apiUsersService: ApiUsersService,
              private usersService: UsersService,
              private projectService: ProjectService,
              private locationGroupsService: LocationGroupsService,
              private rolesService: RolesService,
              private apiRolesService: ApiRolesService,
              private cardsService: CardsService,
              private currentUserStoreService: CurrentUserStoreService,
              private cardOnHolderService: CardOnHolderService,
              private apiProjectService: ApiProjectService,
              private filtersService: FiltersService,
              private alertController: AlertController,
              private pipe: CustomTranslatePipe,
              private toastController: ToastController
              ) {}

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values
    this.mainController = Controller.getMainController(this.controllers)
    this.selectController(this.controllers[0])
    this.getPermissions();
    this.getCardOnHolderStatus();
    this.getIsCardSyncActive();
    this.guestAddedSubscription = this.usersService.newGuestAdded.subscribe( () => {
      this.getCardsByLocation();
    })
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());

    this.apiUsersService.getBackupCardForLocation(this.location.locationId).subscribe( (backupCard: Card) => {
      if (backupCard) {
        this.backupCardExists = true;
      } else {
        this.backupCardExists = false;
      }
    })
  }

  getIsCardSyncActive(){
    this.cardSyncActiveSubscription = this.projectService.cardSyncActive$.subscribe(value => {
      this.cardSyncActive = value;
    });
  }

  selectController(controller: Controller) {
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
    // this.mainController = controller;
    this.getCardsByLocation();
  }


  getCardOnHolderStatus() {
    this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    this.cardOnHolderSubscription = this.cardOnHolderService.cardOnHolderChanged.subscribe(cardOnHolder =>
      this.cardOnHolderChanged(cardOnHolder));
  }

  cardOnHolderChanged(cardOnHolder: boolean) {
    this.cardOnHolder = cardOnHolder;
    if (this.openCardOnReaderModal === true && this.cardOnHolder === true) {
        this.createBackupCard();
    }
  }


  getPermissions() {
    this.permissions = this.rolesService.getPermissions();
    this.apiRolesService.getPermissions().subscribe();
    this.permissionsSubscription = this.rolesService.permissionsChanged.subscribe(() => {
      this.permissions = this.rolesService.getPermissions();
    });
  }

  onRoomSelection(room: Location) {
    this.apiUsersService.getCardsByLocationAndType(room.locationId, 1).subscribe((gCards: Card[]) => {
      const guestCardsOfSelectedRoom = gCards.filter(card => !card.isBackup);
      const freeSpaces =  Controller.getMaxGuestCardsNumber(room.controllers.$values[0]) - 1;
      if (room.locationId !== this.location.locationId && guestCardsOfSelectedRoom.length < freeSpaces) {
        this.openModal = true;
        this.targetRoomToMoveCard = room;
      }else{
        this.writeToastMsg('No available space for cards in that room!');
      }
    });
  }

  async writeToastMsg(msg: string) {
    const toast = await this.toastController.create({
      message: this.pipe.transform(msg),
      duration: 4000,
      // color: 'primary'
    });
    await toast.present();
  }

  getCurrentTime(): Date {
    return new Date();
  }

  getCardsByLocation(stopLoading = false) {
    this.tablesReady = false;
    this.apiUsersService.getCardsByLocationAndType(+this.location.locationId, 1).subscribe( (gCards: Card[]) => {
      this.guestCards = gCards.filter( card => !card.isBackup);
      this.guestCards.sort((a, b) => {
        const dateA = a.validFrom instanceof Date ? a.validFrom.getTime() : new Date(a.validFrom).getTime();
        const dateB = b.validFrom instanceof Date ? b.validFrom.getTime() : new Date(b.validFrom).getTime();
        return dateA - dateB;
      }); //SORTING BY VALID FROM
      this.guestCardsUpdated.next(this.guestCards)
      this.guestFreeSpace = (Controller.getMaxGuestCardsNumber(this.mainController) - 1) - this.guestCards.length
      this.tablesReady = true;
      if (stopLoading) {
        this.loading = false;
      }
    })
  }


  createBackupCard() {
    if (this.cardOnHolder) {
      this.openCardOnReaderModal = false;
      this.cardOnHolderService.cardByUidExists(this.cardsService.lastCardsOnHolderUid).subscribe( (cExists: boolean) => {
        if (cExists === false) {
              const card: Card = new Card();
              card.uid = this.cardsService.lastCardsOnHolderUid;
              card.type = 1;
              card.validFrom = new Date();
              card.blacklisted = 0;
              if (this.backupCardExists) {// backup card exists
                this.apiUsersService.updateBackupCardForLocation(card, this.location.locationId).subscribe();
              } else {
                this.apiUsersService.createBackupCardForLocation(card, this.location.locationId).subscribe(()=> {
                  this.backupCardExists = true;
                });
            }
        } else {
          this.cardOnReaderExist = true;
        }
      })
    } else {
      this.openCardOnReaderModal = true;
    }
  }


  getCardPosition(card: Card) {
    const smallerStr = card.customData.substring(card.customData.indexOf('['),card.customData.indexOf(']'));
    const value = smallerStr.substring(smallerStr.indexOf(':')+1, smallerStr.indexOf(','))

    if (value) {
      return value;
    } else {
      // return this.getBackupPosition()+1;
    }
  }

  moveGuestToAnotherRoom(card: Card) {
    this.roomSwap = true;
    this.movingGuest = card.user;
  }

  async deleteGuest(user: User) {
    const alert = await this.alertController.create({
      header: this.pipe.transform('Confirm delete'),
      message: this.pipe.transform('Delete card?'),
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Delete'),
          handler: () => {
              this.loading = true;
              this.apiUsersService.deleteUser(user.userId).subscribe({
                next:() => {
                  this.getCardsByLocation(true);
                },
                error: ()=> {
                  this.loading = false;
                }
            });
          }
        }
      ],
    });
    await alert.present();

  }

  syncGuest(uid: string) {
    this.loading = true;
    this.apiUsersService.syncCard(uid).subscribe({
      next:()=> {
      this.loading = false;
    },
    error: ()=> {
      this.loading = false;
    }
    }
  );
  }

  onChangeObjectSubobject(event) {
    // this.selectedAccessDesignation = event.target.value;
    this.selectedDesignation = event.target.value;
  }

  onCardOnreaderModalDecision(decision) {
    this.openCardOnReaderModal = false;
 }

 onModalDecisionCardExist(decision) {
  this.cardOnReaderExist = false;
}

  async onModalDecision(decision) {
    if (decision) { // move card and user
      await this.moveCardAndUserToAnotherRoom()
    } else { // click cancel
      this.targetRoomToMoveCard = this.location;
    }
    this.openModal = false;
    this.roomSwap = !this.roomSwap;
  }


  async moveCardAndUserToAnotherRoom() {
    forkJoin([ this.apiProjectService.getKeyOptionsAccessControllListForLocationId(this.targetRoomToMoveCard.locationId),
    this.apiUsersService.getUser(this.movingGuest.userId),
    this.apiProjectService.getAllAccessControlLocations('slim')
    ])
    .subscribe( ([kOs, userToMoveFull, allAccessControllLocations]) => {
      const allAccessControlDesignations = allAccessControllLocations.map((location: Location)=> {return location.locationId})
      const roomsInUserToMove: string[] = userToMoveFull.permissions.reduce((roomsList, perm)=> {
        const permLocationDesignation = perm.locationId
        if ( !allAccessControlDesignations.includes(permLocationDesignation) &&
        perm.typeId === PermissionType.Unlock && permLocationDesignation !== this.location.locationId) {
          roomsList.push(permLocationDesignation.toString())
        }
        return roomsList
      }, [])

      const possibleAccessControllsOnCard: string[] = kOs.reduce((acList, kO)=> {
        const targetPerm = userToMoveFull.permissions.find( (perm:Permission) => perm.locationId == kO.location.locationId)
        if (targetPerm) {
          acList.push(kO.location.locationId.toString())
        }
        return acList;
      }, [])
      this.movingGuest.permissions = [];
      this.permissions.forEach( perm => {
        const locationId = perm.locationId
        if((locationId  && perm.typeId === PermissionType.Unlock) &&
         (this.targetRoomToMoveCard.locationId === locationId
          || possibleAccessControllsOnCard.includes(locationId.toString())
         || roomsInUserToMove.includes(locationId.toString())
         )) {
          this.movingGuest.permissions.push(perm);
        }
      })
      this.loading = true;
      this.apiUsersService.getUser(this.movingGuest.userId).subscribe( user => {
        this.movingGuest.cards = user.cards;
        this.apiUsersService.updateUser(this.movingGuest).subscribe( () => {
          this.getCardsByLocation(true);
        });
      })
    });
  }

  showAccess(event: Event, card: Card) {
    if (event.type === 'mouseenter') {
      this.activePopup = card.uid;
      if (!this.userToShowInPopup) {
        forkJoin([
          this.apiUsersService.getUser(card.userId),
          this.apiProjectService.getKeyOptionsAccessControllListForLocationId(this.location.locationId)
          ]).subscribe(([user, keyOptions])=> {
            this.userToShowInPopup = user;
            this.userToShowInPopup.permissions = user.permissions.filter( (perm: Permission) => {
              // this is returning only access controls
              // const locId = perm.locationId;
              // return keyOptions.some((keyOption: KeyOption)=> {
              //   return keyOption.location.locationId === locId;
              // });

              // this returns rooms too
              return perm.locationId !== this.location.locationId;
            });
          });
      }
    } else if (event.type === 'mouseleave') {
      this.activePopup = undefined;
      this.userToShowInPopup = undefined;
    }
  }

  showAccessPopup(uid: string) {
    if (this.activePopup === uid) {
      return true;
    }
  }

  async editCard(userId: number) {
    const modal = await this.modalController.create({
      component: EditCardModalComponent,
      cssClass: 'cards-popover',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps:  {
        location: this.location,
        userId: userId
        }
    });
    return await modal.present();
  }


  syncAllRoomCards() {
    this.loading = true;
    this.apiUsersService.syncGuestCardsByLocation(this.location.locationId).subscribe({next:()=> {
      this.loading = false;
    },
    error: (error)=> {
      this.loading = false;
      }
    }
    )
  }

  async generateQrCode(card: Card) {
    const modal = await this.modalController.create({
      component: BleKeyQrCodeComponent,
      cssClass: 'qr-code-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps:  {
        card,
        location: this.location
      }
    });
    return await modal.present();
  }

  ngOnDestroy() {
    if (this.permissionsSubscription) {
      this.permissionsSubscription.unsubscribe();
    }
    if (this.guestAddedSubscription) {
      this.guestAddedSubscription.unsubscribe();
    }
    if (this.cardOnHolderSubscription) {
      this.cardOnHolderSubscription.unsubscribe();
    }
    if (this.cardSyncActiveSubscription) {
      this.cardSyncActiveSubscription.unsubscribe();
    }
    this.cardsService.setLastGuestCardOnHolder(undefined);
  }

}
