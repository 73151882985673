<ng-container *ngIf="controllers | findProperty : propFilterFunction as props">
  <div
    *ngIf="forcePropIndex===-1">
  <div *ngFor="let prop of props"
  class="status-container-small flex-row-center-v margin-bottom12 width230">
  <!-- need to check if prop is hvac as we handle icon differently then normal props -->
  <ng-container *ngIf="prop | propIsHvac; else propIsNotHvac">
    <ng-container *ngIf="prop | propIsActive; else propIsHeating">
      <div class="icon-background-on font-size19 icon-snowflake blue-bg"></div>
      <div class="status-text-on"> {{ "Cooling" | translate }} </div>
    </ng-container>
    <ng-template #propIsHeating>
      <div
        class="icon-background-on font-size19 icon-sun orange-bg"
      ></div>
      <div class="status-text-on">{{ "Heating" | translate }}</div>
    </ng-template>
  </ng-container>
  <ng-template #propIsNotHvac>
    <ng-container *ngIf="prop | propIsActive; else propNotActive">
      <div [ngClass]="iconActive" class="icon-background-on font-size19"></div>
      <div class="status-text-on">{{ activeText | translate }}</div>
    </ng-container>
    <ng-template #propNotActive>
      <div
        [ngClass]="iconInactive"
        class="icon-background-off font-size19"
      ></div>
      <div class="status-text-off">{{ inactiveText | translate }}</div>
    </ng-template>
  </ng-template>
</div>
  </div>
  <div
    *ngIf="forcePropIndex!=-1"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
  <!-- need to check if prop is hvac as we handle icon differently then normal props -->
  <ng-container *ngIf="props[forcePropIndex] | propIsHvac; else propIsNotHvac">
    <ng-container *ngIf="props[forcePropIndex] | propIsActive; else propIsHeating">
      <div class="icon-background-on font-size19 icon-snowflake blue-bg"></div>
      <div class="status-text-on"> {{ "Cooling" | translate }} </div>
    </ng-container>
    <ng-template #propIsHeating>
      <div
        class="icon-background-on font-size19 icon-sun orange-bg"
      ></div>
      <div class="status-text-on">{{ "Heating" | translate }}</div>
    </ng-template>
  </ng-container>
  <ng-template #propIsNotHvac>
    <ng-container *ngIf="props[forcePropIndex] | propIsActive; else propNotActive">
      <div [ngClass]="iconActive" class="icon-background-on font-size19"></div>
      <div class="status-text-on">{{ activeText | translate }}</div>
    </ng-container>
    <ng-template #propNotActive>
      <div
        [ngClass]="iconInactive"
        class="icon-background-off font-size19"
      ></div>
      <div class="status-text-off">{{ inactiveText | translate }}</div>
    </ng-template>
  </ng-template>
  </div>
</ng-container>
