import { Router, NavigationStart, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from './shared/services/user-settings.service';
import { IonContent, Platform } from '@ionic/angular';
import { ScrollService } from './shared/services/scroll.service';
import { MqttProjectService } from './core/app-load/mqtt-project.service';
import { MqttService } from 'ngx-mqtt';
import { ENVIRONMENT_MQTT_SERVICE_OPTIONS, USE_DALI } from 'src/environments/environment';
import { Observable, Subscription, delay, interval, take, takeUntil, takeWhile, timer } from 'rxjs';
import { BackBtnService } from './core/services/back-btn.service';
import { AlarmAlertsService } from './modules/alarms/services/alarm-alerts.service';
import { AlarmAlert } from './core/models/alarms/alarm-alert.model';
import { MessagingService } from './core/services/messaging.service';
import { RoomOverviewComponent } from './modules/rooms/pages/room-overview/room-overview.component';
import { ProjectService } from './modules/project/services/project.service';
import { Location } from './core/models/project/location.model';
import { Property } from './core/models/project/property.model';
import { Controller } from './core/models/controller.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  showHeader = false;
  showFooter = true;
  showSidebar = false;
  showMessages = false;
  openModal = false;
  // alarmsFullSize$ = this.alarmAlertsService.getAlarmsSize()
  currentRoute: string;
  savedScrollPosition: string;
  position: number;
  mqttNeedReconect = false;
  mqttTimerRunning = false;
  mqttReconectSubscription: Subscription;
  levelFourAlarmSubscription: Subscription;
  @ViewChild(IonContent) content: IonContent;
  alarmAlert?: AlarmAlert;
  alarmMessageSubscription: Subscription;
  burgRooms: Controller[] = [];
  allLocations$: Observable<Location[]> = this.projectService.getAllLocations();

  constructor(private router: Router,
              public platform: Platform,
              private translate: TranslateService,
              private userSettingsService: UserSettingsService,
              private mqttProjectService: MqttProjectService,
              private mqttService: MqttService,
              private scrollService: ScrollService,
              private backBtnService: BackBtnService,
              private alarmAlertsService: AlarmAlertsService,
              private messagingService: MessagingService,
              private projectService: ProjectService) {
    router.events.subscribe(
      (event) => {
        this.translate.setDefaultLang(this.userSettingsService.getLanguage());
        if (event instanceof NavigationStart) {
        }
        if (event instanceof NavigationEnd) {
          if (event.urlAfterRedirects.includes('login') || event.urlAfterRedirects.includes('license') || event.urlAfterRedirects.includes('projectID')) {
            this.showHeader = false;
            this.showFooter = false;
            this.showSidebar = false;
            this.showMessages = false;
          }
          else {
            this.showHeader = true;
            this.showFooter = true;
            this.showSidebar = true;
            this.showMessages = true;
          }
          if(!this.platform.is('desktop')) {
            this.showSidebar = false;
            this.showMessages = false;
            this.showFooter = false;
          }
        }
      }
    );
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.backBtnService.setIsBackPressed(true);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if(this.currentRoute.toString().includes("rooms")){
      const scrollPositionRooms = event.detail.scrollTop;
      this.currentRoute = window.document.URL.toString();
      this.scrollService.saveScrollPositionRooms(scrollPositionRooms);
    }else if (this.currentRoute.toString().includes("common-area")){
      const scrollPositionCommonAreas = event.detail.scrollTop;
      this.currentRoute = window.document.URL.toString();
      this.scrollService.saveScrollPositionCommonAreas(scrollPositionCommonAreas);
    }
  }

  ngOnInit(): void {
    this.scrollService.saveScrollPositionRooms(0);
    this.scrollService.saveScrollPositionCommonAreas(0);
    this.getIsLevelFourAlarm();
  }

  ngAfterViewInit(): void {
    this.scrollService.setContent(this.content);
  }

  ngAfterViewChecked() {
    this.currentRoute = window.document.URL.toString();
  }


  @HostListener('document:visibilitychange', ['$event'])
  appVisibility() {
   if (document.hidden) {
    this.mqttTimerRunning = true;
    timer(30000).pipe(takeWhile(() => this.mqttTimerRunning == true)).subscribe( () => {
      this.mqttProjectService.disconectedBecauseTabInactive$.next(true);
      this.mqttService.disconnect();
      this.mqttNeedReconect = true;
    })
    }
    else {
      this.mqttTimerRunning = false;
      if (this.mqttNeedReconect) {
        this.mqttService.connect(ENVIRONMENT_MQTT_SERVICE_OPTIONS);
        this.mqttProjectService.disconectedBecauseTabInactive$.next(false);
        this.mqttNeedReconect = false;
      }
    }
}

  isFacilityDetails() {
    if(!this.platform.is('desktop') && (this.router.url.indexOf('/rooms/details') > -1)) {
      return true;
    }
  }

  getAlarmAlert() {
    this.alarmAlert = this.messagingService.getAlarmMessage();
    let controllers: Controller[] = [];
    this.alarmMessageSubscription = this.messagingService.alarmMessageChanged.subscribe((alarmAlert: AlarmAlert) => {
      if(alarmAlert.level === 4){
        this.allLocations$.subscribe((locations) => {
          controllers = [];
          locations.filter((location: Location) => {
            if(location.locationTypeId === 1){
              return locations.some( loc => loc.locationId == location.locationId);
            }
          }).map(location => {
            location.controllers.$values.forEach( cont => {
              controllers.push(cont)
            });
          })
          this.burgRooms = controllers.filter((controller) => {
            return controller.controllerProperties.$values.some((prop: Property) => {
              return Property.isAnyBurglaryProperty(prop) && Property.isActive(prop);
            });
          });
        });
        this.alarmAlert = alarmAlert;
      }
    },);
  }

  getIsLevelFourAlarm(){
    this.getAlarmAlert();
    this.levelFourAlarmSubscription = this.alarmAlertsService.showLevelFourModal$.subscribe(value => {
      this.openModal = value;
    });
  }

  onConfirmLevelFourAlarmModal() {
    this.openModal = false;
 }

 ngOnDestroy(): void {
   if(this.levelFourAlarmSubscription){
    this.levelFourAlarmSubscription.unsubscribe();
   }
   if(this.alarmMessageSubscription){
    this.alarmMessageSubscription.unsubscribe();
   }
 }

}
