import { Injectable } from '@angular/core';
import { IMqttMessage } from 'ngx-mqtt';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { ProjectObject } from 'src/app/core/models/hvac-modes/project-object.model';
import { ProjectSubObject } from 'src/app/core/models/hvac-modes/project-sub-object.model';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { LoadingService } from 'src/app/shared/services/loading.service';

export interface StoredObject {
  userId: number;
  object: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {

  private objects$ = new BehaviorSubject<ProjectObject[]>([]);
  private subObjects$ = new BehaviorSubject<ProjectSubObject[]>([]);


  private filteredControllersFat$ = new BehaviorSubject<Controller[]>([])
  private loadingFilteredControllersFat$ = new BehaviorSubject<boolean>(false)
  private allControllersThin$ = new BehaviorSubject<Controller[]>([])
  updatedPipe$ = new Subject<Date>();

  doorsChanged = new Subject<[string, string]>();

  selectedobjectLocalStorageKey = 'irooms_selected_object';
  loadedObjectForUserId: number;


  private notPolledControllers: Array<Controller> = [];
  notPolledControllersChanged = new Subject<Controller[]>();

  allFatLocationsReady$ = new BehaviorSubject<boolean>(false);
  singleControllerMessage$ = new Subject<IMqttMessage>();
  goToRetroMode$ = new BehaviorSubject<boolean>(false);

  private filteredLocations$ = new BehaviorSubject<Location[]>([]);
  private allLocations$ = new BehaviorSubject<Location[]>([]);
  cardSyncActive$ = new BehaviorSubject<boolean>(false);

  constructor(private loadingService: LoadingService) {}

  //NEW REQUESTS

  getFilteredLocations() {
    return this.filteredLocations$.asObservable();
  }

  setFilteredLocations(locations: Location[]) {
    this.filteredLocations$.next(locations);
  }

  getAllLocations() {
    return this.allLocations$.asObservable();
  }

  setAllLocations(locations: Location[], fatReady?: boolean) {
    if (fatReady) {
      this.allFatLocationsReady$.next(true);
    }
    this.allLocations$.next(locations)
  }

  getFilteredLocationById(locationId: string) {
    return this.filteredLocations$.getValue().find( loc => loc.locationId === +locationId)
  }

  getLocationById(locationId: string) {
    return this.allLocations$.getValue().find( loc => loc.locationId === +locationId)
  }

  getGuestRoomLocations() {
    return this.allLocations$.getValue().filter( location => Location.isGuestRoom(location));
  }

  getAccessControlLocations$() {
    return of(this.allLocations$.getValue().filter( location => Location.isAccessControl(location)));
  }
  //NEW REQUESTS




  setFilteredControllersFat(controllers: Controller[]) {
    this.filteredControllersFat$.next(controllers);
  }

  getLoadingFilteredLocations() {
    return this.loadingFilteredControllersFat$.asObservable();
  }


  setLoadingFilteredLocations(loading: boolean) {
    this.loadingFilteredControllersFat$.next(loading);
  }




  getRoom2Controllers$() {
    const locations = this.allLocations$.getValue().filter( location => Location.isGuestRoom(location))
    const controllers = locations.reduce( (roomList, location) => {
        roomList.push(...location.controllers.$values);
      return roomList
    }, [])
    return of(controllers)
  }

  getHvacControllers$() {
    const locations = this.allLocations$.getValue().filter( location => Location.isHvacCommonArea(location))
    const controllers = locations.reduce( (roomList, location) => {
        roomList.push(...location.controllers.$values);
      return roomList
    }, [])
    return of(controllers)
  }

  getLightControllers$() {
    const locations = this.allLocations$.getValue().filter( location => Location.isIOCommonArea(location))
    const controllers = locations.reduce( (roomList, location) => {
        roomList.push(...location.controllers.$values);
      return roomList
    }, [])
    return of(controllers)
  }

  getAccessControllControllers$() {
    const locations = this.allLocations$.getValue().filter( location => Location.isAccessControl(location))
    const controllers = locations.reduce( (roomList, location) => {
        roomList.push(...location.controllers.$values);
      return roomList
    }, [])
    return of(controllers)
  }


  getAllControllersThin() {
    return this.allControllersThin$.asObservable();
  }


  setObjects(objects: ProjectObject[]){
    this.objects$.next(objects)
  }

  getObjects(): Observable<ProjectObject[]> {
    return this.objects$.asObservable();
  }

  setSubObjects(subObjects: ProjectSubObject[]){
    this.subObjects$.next(subObjects)
  }

  getSubObjects() {
    return this.subObjects$.asObservable();
  }



  getControllerByLocationId(locId: string) {
  let targetCont: Controller
   this.allLocations$.getValue().find( location => { location.controllers.$values.find( cont => {
    if (cont.locationId == +locId) {
      targetCont = cont
    }
   })})
   if (targetCont) {
    return targetCont
   }
  }

  getThinLocationByRoomNumber(roomNo: string) {
    return this.allLocations$.getValue().find( loc => {
      return Location.getLocationZone(loc) === roomNo.toString()
    })
  }

  getControllerByDesignation(designation: string) {
    let targetCont: Controller;
    this.filteredLocations$.getValue().some( location => {
      targetCont = location.controllers.$values.find((cont: Controller)=> {
          return cont.designation === designation;
        })
        return targetCont
    })
    return targetCont
  }

  getControllerByDesignationFromFull(designation: string) {
    let targetCont: Controller;
    this.allLocations$.getValue().some( location => {
      targetCont = location.controllers.$values.find((cont: Controller)=> {
          return cont.designation === designation;
        })
        return targetCont
    })
    return targetCont
  }


  findPropByType(controller: Controller, propDown: number, propUp: number) {
    return controller.controllerProperties.$values.find( prop => {
      if (prop.type >= propDown && prop.type <= propUp) {
        return true;
      }
    })
  }

  updateFilteredProjectByMqtt(message: IMqttMessage) {
    const filteredLocations = this.filteredLocations$.getValue()
    if (filteredLocations.length === 0) {
      return;
    }
    const payloadIndex = message.payload.toString().lastIndexOf('|');
    const payload = message.payload.toString().slice(payloadIndex + 1);

    // example topic cli/driver/1/0/0/101/0/hvac/temp/set/room
    const driverPref = message.topic.split('/')[1] + '/' + message.topic.split('/')[2];
    const obj = message.topic.split('/')[3];
    const subObj = message.topic.split('/')[4];
    const zoneId = message.topic.split('/')[5];
    const subZoneId = message.topic.split('/')[6];
    const propTopic = this.getPropTopic(message.topic);
    let targetProp: Property;
    let targetCont: Controller;

    if (propTopic) {
      filteredLocations.find( location => {
        return location.controllers.$values.some( cont => {
          if (cont.driverPrefix.toLowerCase() === driverPref.toLowerCase() && cont.object === obj && cont.subObject === subObj && cont.zone === zoneId && cont.subZone === subZoneId) {
            targetCont = cont;
            targetProp = cont.controllerProperties.$values.find((prop)=> {
              return prop.mqttTopic === propTopic
            })
            return true;
          }
        })
      })
    }
    if (targetProp) {
      this.loadingService.removeFromLoading(`${targetCont.id}-${targetProp.id}`)
      targetProp.value = payload;
      this.setFilteredLocations(filteredLocations);

      // we use this for 3D model of room
      if (Property.isDoorOpened(targetProp)) {
        this.doorsChanged.next([targetProp.value, obj + '/' + subObj + '/' + zoneId + '/' + subZoneId]);
      }
      this.updatePipe();
    }
  }

  updateFullProjectByMqtt(message: IMqttMessage) {
    const allLocations = this.allLocations$.getValue();
    if (allLocations.length === 0) {
      return;
    }
    const payloadIndex = message.payload.toString().lastIndexOf('|');
    const payload = message.payload.toString().slice(payloadIndex + 1);

    // example topic cli/driver/1/0/0/101/0/hvac/temp/set/room
    const driverPref = message.topic.split('/')[1] + '/' + message.topic.split('/')[2];
    const obj = message.topic.split('/')[3];
    const subObj = message.topic.split('/')[4];
    const zoneId = message.topic.split('/')[5];
    const subZoneId = message.topic.split('/')[6];
    const propTopic = this.getPropTopic(message.topic);
    let targetProp: Property;

    if (propTopic) {
      allLocations.find( location => {
        return location.controllers.$values.some( cont => {
          if (cont.driverPrefix.toLowerCase() === driverPref.toLowerCase() && cont.object === obj && cont.subObject === subObj && cont.zone === zoneId && cont.subZone === subZoneId) {
            targetProp = cont.controllerProperties.$values.find((prop)=> {
              return prop.mqttTopic === propTopic
            })
            return true;
          }
        })
      })
    }

    if (targetProp) {
      targetProp.value = payload;
      this.setAllLocations(allLocations);

      // we use this for 3D model of room
      if (Property.isDoorOpened(targetProp)) {
        this.doorsChanged.next([targetProp.value, obj + '/' + subObj + '/' + zoneId + '/' + subZoneId]);
      }
      this.updatePipe();
    }
  }



  updateControllerIsOnlineStatus(designation: string, isOnline: boolean) {
    const controller = this.getControllerByDesignation(designation);
    const controllerFromFull = this.getControllerByDesignationFromFull(designation)
    if (controller) {
      controller.isOnline = isOnline;
    }

    if (controllerFromFull) {
      controllerFromFull.isOnline = isOnline;
    }
    this.updatePipe();
  }

  updateLocationIsRentedStatus(locationId: string, isRented: boolean) {
    const location = this.getFilteredLocationById(locationId);
    const locationFromFull = this.getLocationById(locationId)
    if (location) {
      location.isRented = isRented;
    }

    if (locationFromFull) {
      locationFromFull.isRented = isRented;
    }
    this.updatePipe();
  }

  updateLocationIsJoinedStatus(locationId: string, isJoined: boolean) {
    const location = this.getFilteredLocationById(locationId);
    const locationFromFull = this.getLocationById(locationId)
    if (location) {
      location.isJoined = isJoined;
    }

    if (locationFromFull) {
      locationFromFull.isJoined = isJoined;
    }
    this.updatePipe();
  }

  updateLocationIsOnlineStatus(locationId: string, isOnline: boolean) {
    const location = this.getFilteredLocationById(locationId);
    const locationFromFull = this.getLocationById(locationId);

    if (location) {
      location.isOnline = isOnline;
    }

    if (locationFromFull) {
      locationFromFull.isOnline = isOnline;
    }
    this.updatePipe();
  }

  getPropTopic(topic: string): string { // example input: dev/irooms/1/0/0/101/1/hvac/temp/meas/room,
    const topicArray = topic.split('/');
    let propTopic = '';
    for (let index = 7; index < topicArray.length; index++) {
      propTopic = propTopic + topicArray[index];
      if (index < topicArray.length-1) {
        propTopic = propTopic + '/';
      }
    }
    return propTopic;  // example output : hvac/temp/meas/room
  }

  updatePipe() {
    const date = new Date();
    this.updatedPipe$.next(date);
  }

  setNotPolledControllers(controllers: Controller[]) {
    this.notPolledControllers = controllers;
    this.notPolledControllersChanged.next(this.notPolledControllers);
  }

  getNotPolledControllers() {
    return this.notPolledControllers.slice();
  }

  isControllerPolled(controller: Controller) {
    let stopPolling = false;
    const controllers = this.getNotPolledControllers();
    controllers.forEach( cont => {
      if(cont.object === controller.object && cont.subObject === controller.subObject
        && cont.zone === controller.zone && cont.subZone === controller.subZone) {
        stopPolling = true;
      }
    })
    return stopPolling;
  }

  handleCardSyncActive(message: IMqttMessage){
    const value = message.payload.toString().split('|').at(-1);
    if(value === '1'){
      this.cardSyncActive$.next(true);
    }else{
      this.cardSyncActive$.next(false);
    }
  }

}



