
    <div class="log-title backgroundBlue flex-row-center-h">
      {{'Access control log' | translate}}
  </div>

  <div class="flex-row flex-row-space-evenly margin-left20 margin-top10 margin-right20">
      <ion-item class="width100">
        <ion-segment [(ngModel)]="activeCard"  (ngModelChange)="cardClicked($event)" >

          <ion-segment-button *ngIf="signedInUser | userHasPermission : swPermissions.AccessLogView" class="width50" value="access">
            <ion-label class="ion-text-wrap">{{'Access log' | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button *ngIf="signedInUser | userHasPermission : swPermissions.AlarmsLogView" class="width50" value="alarm">
            <ion-label class="ion-text-wrap">{{'Alarm log' | translate}}</ion-label>
          </ion-segment-button>
          <ion-segment-button *ngIf="signedInUser | userHasPermission : swPermissions.ValueLogView" class="width50" value="fullGraph">
            <ion-label  class="ion-text-wrap">{{'Value log' | translate}}</ion-label>
          </ion-segment-button>
        </ion-segment>
    </ion-item>
    </div>


  <div *ngIf="activeCard === 'access'">
  <app-access-logs [controllers]="controllers" [singleRoom]="true"></app-access-logs>
  </div>
  <div *ngIf="activeCard === 'alarm'">
      <app-alarm-logs [controllers]="controllers" [singleRoom]="true"></app-alarm-logs>
  </div>

  <div class="content-container" *ngIf="activeCard === 'fullGraph'">
    <div class="flex-row-center-v gap5 margin-top10 margin-left20">
      <ion-button color="primary" size="small" (click)="showLast24hours()">24 H</ion-button>
      <ion-button color="primary" size="small" (click)="showLast7days()">7 D</ion-button>
      <ion-button color="primary" size="small" (click)="showLast30days()">30 D</ion-button>
      <ion-button color="primary" size="small" (click)="showCustom()">{{'Custom' | translate}}</ion-button>
      <div class="flex-row-center-v margin-left10">
        <select [(ngModel)]="selectedController" (ngModelChange)="controllerChanged($event)">
          <option *ngFor="let controller of controllers" [ngValue]="controller"> {{ controller.name }}</option>
          </select>
      </div>
      <ion-list class="width40 margin-left20">
        <ion-item>
          <ion-select *ngIf="showSelect" multiple="true" (ionChange)="selectChanged($event)" [(ngModel)]="propSelect" label="{{'Properties' | translate}}" (click)="simulateClick()" (ionCancel)="onDismiss()" [interfaceOptions]="{cssClass: 'ionic-custom-select'}">
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDoorOpened : searchValue" [value]="Property.isDoorOpened">{{'Door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWindowOpened : searchValue" [value]="Property.isWindowOpened">{{'Window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCleaningStatus : searchValue" [value]="Property.isCleaningStatus">{{'Cleaning status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isInspectedStatus : searchValue" [value]="Property.isInspectedStatus">{{'Inspected status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDoorLock : searchValue" [value]="Property.isDoorLock">{{'Door locked' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedWindow : searchValue" [value]="Property.isBurglaryDetectedWindow">{{'Burglary detected on window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedDoor : searchValue" [value]="Property.isBurglaryDetectedDoor">{{'Burglary detected on door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDndStatus : searchValue" [value]="Property.isDndStatus">{{'DND status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isMinibarWasOpenedStatus : searchValue" [value]="Property.isMinibarWasOpenedStatus">{{'Minibar was opened' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWelcomeLightStatus : searchValue" [value]="Property.isWelcomeLightStatus">{{'Welcome light status' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV1 : searchValue" [value]="Property.isFancoilV1">{{'Fancoil V1' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV2 : searchValue" [value]="Property.isFancoilV2">{{'Fancoil V2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFancoilV3 : searchValue" [value]="Property.isFancoilV3">{{'Fancoil V3' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomRadiator : searchValue" [value]="Property.isBathroomRadiator">{{'Bathroom radiator' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBathroomFloorHeating : searchValue" [value]="Property.isBathroomFloorHeating">{{'Bathroom floor heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomFloorHeating : searchValue" [value]="Property.isRoomFloorHeating">{{'Room floor heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHVACLargeTempDiffRoom : searchValue" [value]="Property.isHVACLargeTempDiffRoom">{{'HVAC large temp diff room' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHVACLargeTempDiffBathroom : searchValue" [value]="Property.isHVACLargeTempDiffBathroom">{{'HVAC large temp diff bathroom' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRegulation : searchValue" [value]="Property.isHvacRegulation">{{'HVAC regulation' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacCalibration : searchValue" [value]="Property.isHvacCalibration">{{'HVAC calibration' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomEcoHyst1 : searchValue" [value]="Property.isHvacRoomEcoHyst1">{{'HVAC room eco hys 1' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomEcoHyst2 : searchValue" [value]="Property.isHvacRoomEcoHyst2">{{'HVAC room eco hys 2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacBathroomEcoHyst1 : searchValue" [value]="Property.isHvacBathroomEcoHyst1">{{'HVAC bathroom eco hys 1' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacBathroomEcoHyst2 : searchValue" [value]="Property.isHvacBathroomEcoHyst2">{{'HVAC bathroom eco hys 2' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacHeatCool : searchValue" [value]="Property.isHvacHeatCool">{{'HVAC heating cooling' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacHeatCoolAuto : searchValue" [value]="Property.isHvacHeatCoolAuto">{{'HVAC heating cooling auto' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFlood : searchValue" [value]="Property.isFlood">{{'Flood' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacActive : searchValue" [value]="Property.isHvacActive">{{'HVAC active' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilOff : searchValue" [value]="Property.isHvacRoomControlFancoilOff">{{'HVAC room control fancoil off' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilLow : searchValue" [value]="Property.isHvacRoomControlFancoilLow">{{'HVAC room control fancoil low' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilMedium : searchValue" [value]="Property.isHvacRoomControlFancoilMedium">{{'HVAC room control fancoil medium' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilHigh : searchValue" [value]="Property.isHvacRoomControlFancoilHigh">{{'HVAC room control fancoil high' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isHvacRoomControlFancoilAuto : searchValue" [value]="Property.isHvacRoomControlFancoilAuto">{{'HVAC room control fancoil auto' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeBurglary : searchValue" [value]="Property.isSafeBurglary">{{'Safe burglary' |translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeCommError : searchValue" [value]="Property.isSafeCommError">{{'Safe comm error' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSafeDoor : searchValue" [value]="Property.isSafeDoor">{{'Safe door' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomArmed : searchValue" [value]="Property.isRoomArmed">{{'Room armed' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglary : searchValue" [value]="Property.isBurglary">{{'Burglary' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isBurglaryDetectedByPIR : searchValue" [value]="Property.isBurglaryDetectedByPIR">{{'Burglary detected by PIR' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isIgnoreCardTray : searchValue" [value]="Property.isIgnoreCardTray">{{'Ignore card tray' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCardType : searchValue" [value]="Property.isCardType">{{'Card type' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isIgnoreWindow : searchValue" [value]="Property.isIgnoreWindow">{{'Ignore window' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isSOS : searchValue" [value]="Property.isSOS">{{'SOS' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPirInput : searchValue" [value]="Property.isPirInput">{{'PIR input' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPanelBlocked : searchValue" [value]="Property.isPanelBlocked">{{'Panel blocked' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCardDeposited : searchValue" [value]="Property.isCardDeposited">{{'Card deposited' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isValveHeating : searchValue" [value]="Property.isValveHeating">{{'Valve heating' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isValveCooling : searchValue" [value]="Property.isValveCooling">{{'Valve cooling' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isNewCard : searchValue" [value]="Property.isNewCard">{{'New card' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isDumper : searchValue" [value]="Property.isDumper">{{'Dumper' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isContactor : searchValue" [value]="Property.isContactor">{{'Contactor' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWelcomeLight : searchValue" [value]="Property.isWelcomeLight">{{'Welcome light' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isLight : searchValue" [value]="Property.isLight">{{'Light' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isMakeUpRoom : searchValue" [value]="Property.isMakeUpRoom">{{'Make up room' | translate}}</ion-select-option>
            <!-- NEW PROPS -->
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isControllerTempHigh : searchValue" [value]="Property.isControllerTempHigh">{{'High equipment temperature' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isRoomBurglaryPIR : searchValue" [value]="Property.isRoomBurglaryPIR">{{'Room burglary detected by PIR' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFuse : searchValue" [value]="Property.isFuse">{{'Fuse problem' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isPowerOutage : searchValue" [value]="Property.isPowerOutage">{{'Power outage' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isFire : searchValue" [value]="Property.isFire">{{'Fire' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isWind : searchValue" [value]="Property.isWind">{{'Strong wind' | translate}}</ion-select-option>
            <ion-select-option *ngIf="selectedController | propExistInController : Property.isCO2 : searchValue" [value]="Property.isCO2">{{'CO2 level high' | translate}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
      <div class="margin-left20">
        <ion-checkbox color="primary" (ionChange)="onAccessLogClick($event)"> {{'Access log' | translate}}</ion-checkbox>
      </div>
      <div class="margin-left20">
        <ion-checkbox color="primary" (ionChange)="onAlarmLogClick($event)">{{'Alarm log' | translate}}</ion-checkbox>
      </div>
      <app-date-time-from-to #dateTimeFromTo (selectedDateTimeFromTo)="setDateTimeFromTo($event)" [hidden]="hideCustomTime"></app-date-time-from-to>
    </div>
    <div *ngIf="(tableCreated && tableProperties.length > 0) || ((giveAccess || giveAlarm) && !logsLoading)">
      <div class="flex-row-center-h margin-top20 width100">
          <table>
            <tr>
              <th class="">{{'Time recorded' | translate}}</th>
              <th *ngIf="tableCreated && tableProperties.length > 0" class=" ">{{'Property' | translate}}</th>
              <th *ngIf="tableCreated && tableProperties.length > 0" class=" ">{{'Value' | translate}}</th>
              <th *ngIf="giveAccess" class=" ">{{'User' | translate}}</th>
              <th *ngIf="giveAccess" class=" ">{{'Location' | translate}}</th>
              <th *ngIf="giveAlarm" class=" ">{{'Level' | translate}}</th>
              <th *ngIf="giveAlarm" class=" ">{{'Description' | translate}}</th>
            </tr>
              <tr *ngFor="let data of tableData; let i = index" class="br-gray-b">
                <td *ngIf="showDataAtPage(i)" class="   ">
                 {{ data.timestamp | date:'dd.MM.yyyy. HH:mm:ss'}}
                </td>
                <td  *ngIf="tableCreated && tableProperties.length > 0 && showDataAtPage(i)" class=" ">
                  {{ getPropertyName(data.propertyType) }}
                </td>
                <td *ngIf="tableCreated && tableProperties.length > 0 && showDataAtPage(i)" class=" ">
                  {{ data.value}}
                </td>
                <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                  {{ data.user}}
                </td>
                <td *ngIf="giveAccess && showDataAtPage(i)" class=" ">
                  {{ data.locationName}}
                </td>
                <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                  {{ data.level}}
                </td>
                <td *ngIf="giveAlarm && showDataAtPage(i)" class=" ">
                  {{ data.description}}
                </td>
              </tr>
          </table>
        </div>
        <div class="flex-row width95 margin-top5">
          <app-room-log-paginator
            class="to-right"
            [paginationData]="tableData"
            (targetPage)="onPageChange($event)">
          </app-room-log-paginator>
        </div>
    </div>
  <div *ngIf="(!tableCreated && tableProperties.length > 0) || logsLoading" class="width100 flex-row-center-h"><div class="lds-dual-ring"></div></div>
  </div>

