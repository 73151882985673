import { Component, Input, OnInit,  } from '@angular/core';

import { ApiAlarmLogsService } from '../../services/http/api-alarm-logs.service';
import { AlarmLog } from 'src/app/core/models/logs/alarm-log.model';
import { PaginationData } from 'src/app/core/models/pagination-data.model';
import { SsFilterSort } from 'src/app/core/models/ss-filter-sort.model';
import { Controller } from 'src/app/core/models/controller.model';
import { UserSettingsService } from 'src/app/shared/services/user-settings.service';
import { AlertController } from '@ionic/angular';
import { CustomTranslatePipe } from 'src/app/shared/pipes/custom-translate.pipe';

@Component({
  selector: 'app-alarm-logs',
  templateUrl: './alarm-logs.component.html',
  styleUrls: ['./alarm-logs.component.scss']
})
export class AlarmLogsComponent implements OnInit {

  @Input() controllers: Controller[];
  @Input() singleRoom: boolean;
  mainController: Controller;
  alarmLogData: AlarmLog[]  = [];
  paginationData: PaginationData = new PaginationData();

  queryParams = {
    pageNumber: 1,
    pageSize: 50
  };
  requestBody: SsFilterSort = new SsFilterSort();
  loading = false;
  language: string;
  downloadInProgress = false;

  constructor(private apiAlarmLogsService: ApiAlarmLogsService,
              private userLanguageService: UserSettingsService,
              private alertController: AlertController,
              private pipe: CustomTranslatePipe) { }

  ngOnInit(): void {
    if (this.singleRoom) {
      this.mainController = Controller.getMainController(this.controllers)
      const designationFilter: string[] = this.controllers.map((cont: Controller)=> {
        return cont.designation
      })
      this.requestBody.controllerDesignations = designationFilter;
    }
    this.language = this.userLanguageService.getLanguage()

    this.onSubmit(1, 'DSC|TIME');
  }


  controllerChanged(controller: Controller) {
    this.mainController = controller;
    this.onSubmit(1, 'DSC|TIME');
  }


  onSubmit(pageNumber: number = 1, pageSort?: string) {
    this.loading = true;
    if (pageSort) {
       this.requestBody.sorting = pageSort;
      }


    this.queryParams.pageNumber = pageNumber;
    this.apiAlarmLogsService.getAlarmLogs(this.requestBody, this.queryParams).subscribe(resp => {
      this.paginationData = JSON.parse(resp.headers.get('X-Pagination'));
      this.alarmLogData = resp.body;
      this.loading = false;
    });
  }

  onSort(sortBy: string) {
    if (this.requestBody.sorting === 'ASC|' + sortBy) {
      this.requestBody.sorting = 'DSC|' + sortBy;
    } else {
      this.requestBody.sorting = 'ASC|' + sortBy;
    }
    this.onSubmit(this.queryParams.pageNumber);
  }

  searchFilterReady(searchFilter: string) {
    this.requestBody.filtering = searchFilter;
    this.onSubmit();
  }

  async confirmDownload(fileType: string) {
    let message = '';
    if (fileType === 'PDF') {
      message = this.pipe.transform('Maximum event number is 5000')
    }
    const alert = await this.alertController.create({
      header: this.pipe.transform('Do you want to download ') + fileType + this.pipe.transform(' file?'),
      message: message,
      buttons: [ {
        text: this.pipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.pipe.transform('Accept'),
          handler: () => {
            this.downloadInProgress = true;
            if (fileType === 'CSV') {
              this.exportToCSV()
            } else if (fileType === 'PDF') {
              this.exportToPDF()
            }

          }
        }
      ],
    });
    await alert.present();

  }

  exportToCSV() {
    this.apiAlarmLogsService.exportLogToCSV(this.requestBody, this.language).subscribe( response => {
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      const objectURL = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.href = objectURL
      downloadLink.setAttribute('download', 'AlarmLogExport.csv');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(objectURL);
      this.downloadInProgress = false;
    })
  }

  exportToPDF() {
    this.apiAlarmLogsService.exportLogToPDF(this.requestBody, this.language).subscribe( response => {
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      const objectURL = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
      downloadLink.href = objectURL
      downloadLink.setAttribute('download', 'AlarmLogExport.pdf');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(objectURL);
      this.downloadInProgress = false;
    })
  }


  setDateTimeFromTo(selectedDateTime: Date[]) {
    this.requestBody.dateTimeFrom = selectedDateTime[0];
    this.requestBody.dateTimeTo = selectedDateTime[1];
    this.onSubmit();
  }

}
