import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

import { setBaseUrl, setVersionCheckUrl, setEnvironmentMqtt, setCardBackupYearsActive,
   ENVIRONMENT_MQTT_SERVICE_OPTIONS, setUseDali, setCardWarning, setCardDanger, setRetroMode, setMQTT } from 'src/environments/environment';
import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { CardReaderService } from '../services/card-reader.service';
import { Router } from '@angular/router';

interface Configuration {
  API_BASE_URL: string;
  VERSION_CHECK_URL: string;
  PORT: string,
  API_URL: string,
  API_BASE: string,
  API_FROM_CONFIG: boolean,
  MQTT_SETTINGS: IMqttServiceOptions;
  BACKUP_CARD_YEARS_ACTIVE: number;
  USE_DALI: boolean,
  CARD_DANGER: number;
  CARD_WARNING: number;
  RETRO_MODE: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigLoaderService {

  private readonly CONFIG_URL = 'assets/config/config.json';
  private configuration$: Observable<Configuration>;

  constructor(
    private http: HttpClient,
    private mqttService: MqttService,
    private cardReaderService: CardReaderService
              ) {
  }

  public loadConfiguration(): any {
    if (!this.configuration$) {
      this.configuration$ = this.http.get<Configuration>(this.CONFIG_URL).pipe(
        shareReplay(1)
      );
      this.configuration$.subscribe(config => {
        setEnvironmentMqtt(config.MQTT_SETTINGS)
        if (config.API_FROM_CONFIG) {
          setBaseUrl(config.PORT, config.API_URL, config.API_BASE);
        } else {
          let url = window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]
          url = url.split(":", 2).join(":")
          const mqttUrl = url.split('/')
          setBaseUrl(config.PORT, url, config.API_BASE)
          setMQTT(mqttUrl[0], mqttUrl[2])
        }
        setVersionCheckUrl(config.VERSION_CHECK_URL);
        setCardBackupYearsActive(config.BACKUP_CARD_YEARS_ACTIVE);
        setUseDali(config.USE_DALI)
        setCardWarning(config.CARD_WARNING)
        setCardDanger(config.CARD_DANGER)
        setRetroMode(config.RETRO_MODE)
        this.mqttService.connect(ENVIRONMENT_MQTT_SERVICE_OPTIONS)
        this.cardReaderService.initialize()
      });
    }
    return lastValueFrom(this.configuration$);
  }
}
