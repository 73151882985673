<ng-container *ngIf="location.controllers.$values.length > 0 && propToDisplay">
  <!-- SINGLE PROP TYPE -->
  <ng-container *ngIf="propToDisplay.mode === 'singlePropType'">
    <ng-container
      *ngIf="
        location.controllers.$values
          | findPropertyByCode
            : propToDisplay.propInfo.codeFrom
            : propToDisplay.propInfo.codeTo : updated: displaySize as properties
      "
    >
      <ng-container
        *ngIf="
          properties
            | propertiesAreActive : propToDisplay.propInfo.activeCondition;
          else propertiesNotActive
        "
      >
        <div
          *ngIf="
            displaySize === 'facility' &&
            propDisplayArray.includes(propToDisplay.propInfo.activeName)
          "
          class="facility-hover-status"
        >
          {{ propToDisplay.propInfo.activeName | translate }}
        </div>
        <div
          [title]="propToDisplay.propInfo.activeTooltip | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-on"
            [ngClass]="[
              propToDisplay.propInfo.activeIcon,
              displaySize === 'facility' ? 'facility-icon' : ''
            ]"
          ></div>

          <div *ngIf="displaySize === 'large'" class="status-text-on">
            {{ propToDisplay.propInfo.activeName | translate }}
          </div>
        </div>
      </ng-container>
      <ng-template #propertiesNotActive>
        <div
          *ngIf="
            displaySize === 'facility' &&
            propDisplayArray.includes(propToDisplay.propInfo.inactiveName)
          "
          class="facility-hover-status"
        >
          {{ propToDisplay.propInfo.inactiveName | translate }}
        </div>
        <div
          [title]="propToDisplay.propInfo.inactiveTooltip | translate"
          [ngClass]="[
            displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
          ]"
          class="full-height"
        >
          <div
            class="icon-background-off"
            [ngClass]="[
              propToDisplay.propInfo.inactiveIcon,
              displaySize === 'facility' ? 'facility-icon' : ''
            ]"
          ></div>
          <div *ngIf="displaySize === 'large'" class="status-text-off">
            {{ propToDisplay.propInfo.inactiveName | translate }}
          </div>
        </div></ng-template
      >
    </ng-container>
  </ng-container>
  <!-- NUMERICAL PROP TYPE -->

  <ng-container *ngIf="propToDisplay.mode === 'numericalPropType'">
    <ng-container
      *ngIf="
        location.controllers.$values
          | findPropertyByCode
            : propToDisplay.propInfo.codeFrom
            : propToDisplay.propInfo.codeTo : updated : displaySize as properties
      "
    >
      <div
        *ngIf="
          displaySize === 'facility' &&
          propDisplayArray.includes(propToDisplay.propInfo.activeIcon)
        "
        class="facility-hover-status"
      >
        {{ propToDisplay.propInfo.activeTooltip }}
      </div>
      <div
        [title]="propToDisplay.propInfo.activeTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'status-container-minimal' : ''
        ]"
        class="flex-row full-height"
      >
        <div
          class="icon-background-off"
          [ngClass]="[
            propToDisplay.propInfo.activeIcon,
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>
        <div class="status-text-on">
          <ng-container
            *ngIf="
              properties[0]?.value !== null && properties[0]?.value !== undefined;
              else noTemp
            "
            >{{ properties[0].value }}
            <ng-container
              *ngIf="displaySize === 'large' || displaySize === 'facility'"
            >
              {{ propToDisplay.propInfo.sufix }}
            </ng-container></ng-container
          >
          <ng-template #noTemp> - </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <!-- STATUS -->

  <ng-container *ngIf="propToDisplay.mode === 'status'">
    <ng-container
      *ngIf="
        location
          | statusIsActive
            : propToDisplay.propInfo.statusName
            : propToDisplay.propInfo.activeCondition
            : updated;
        else statusNotActive
      "
    >
      <div
        *ngIf="
          displaySize === 'facility' &&
          propDisplayArray.includes(propToDisplay.propInfo.activeName)
        "
        class="facility-hover-status"
      >
        {{ propToDisplay.propInfo.activeName | translate }}
      </div>
      <div
        [title]="propToDisplay.propInfo.activeTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-on"
          [ngClass]="[
            propToDisplay.propInfo.activeIcon,
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>
        <div *ngIf="displaySize === 'large'" class="status-text-on">
          {{ propToDisplay.propInfo.activeName | translate }}
        </div>
      </div>
    </ng-container>
    <ng-template #statusNotActive>
      <div
        *ngIf="
          displaySize === 'facility' &&
          propDisplayArray.includes(propToDisplay.propInfo.inactiveName)
        "
        class="facility-hover-status"
      >
        {{ propToDisplay.propInfo.inactiveName | translate }}
      </div>
      <div
        [title]="propToDisplay.propInfo.inactiveTooltip | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-off"
          [ngClass]="[
            propToDisplay.propInfo.inactiveIcon,
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>
        <div *ngIf="displaySize === 'large'" class="status-text-off">
          {{ propToDisplay.propInfo.inactiveName | translate  }}
        </div>
      </div></ng-template
    >
  </ng-container>

  <!-- CUSTOM (FAN SPEED) -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(fanSpeed)'">
    <ng-container
      *ngIf="
      location.controllers.$values
          | getFirstControllerWithProperty : Property.isFancoilSpeed : updated
          | findProperty : Property.isFancoilSpeed as fanCoilSpeedProps
      "
    >
      <ng-container
        *ngIf="fanCoilSpeedProps | getActiveProps as activeFanCoilSpeedProps"
      >
        <div
          [ngClass]="[
            displaySize === 'minimal' ? 'status-container-minimal' : ''
          ]"
          class="flex-row full-height"
        >
          <!-- <div *ngIf="displaySize === 'facility' && propDisplayArray.includes('fanSpeed')" class="facility-hover-status">
            {{'Fan speed' | translate }}
          </div> -->
          <div
            [ngClass]="[
              propToDisplay.propInfo.activeIcon,
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length > 0
                ? 'icon-background-on'
                : '',
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length === 0
                ? 'icon-background-off'
                : '',
              displaySize === 'facility' ? 'facility-icon' : ''
            ]"
            class="font-size19"
          ></div>
          <div
            *ngIf="
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length > 0
            "
            class="status-text-on"
          >
            <span
              *ngIf="Property.isFancoilV1(activeFanCoilSpeedProps[0])"
              [title]="'V1'"
            >
              V1
            </span>
            <span
              *ngIf="Property.isFancoilV2(activeFanCoilSpeedProps[0])"
              [title]="'V2'"
            >
              V2
            </span>
            <span
              *ngIf="Property.isFancoilV3(activeFanCoilSpeedProps[0])"
              [title]="'V3'"
            >
              V3
            </span>
          </div>

          <div
            *ngIf="
              (displaySize === 'large' || displaySize === 'minimal') &&
              activeFanCoilSpeedProps.length === 0
            "
            title="{{ 'Off' | translate }}"
            class="status-text-off"
          >
            {{ "Off" | translate }}
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- DAIKIN UNIT STATUS! -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(daikinUnitStatus)'">
    <ng-container
    *ngIf="
       location.controllers.$values
          | getFirstControllerWithProperty : Property.isDaikinUnitStatus : updated
          | findProperty : Property.isDaikinUnitStatus as daikinUnitStatusProps
    "
  >
  <!-- 0 Error -->
    <ng-container
      *ngIf="daikinUnitStatusProps[0]?.value === 0 || daikinUnitStatusProps[0]?.value === '0'"
    >
      <div
        [title]="'Daikin status error' | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-off"
          [ngClass]="[
            'icon-temp font-size19',
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>

        <div *ngIf="displaySize === 'large'" class="status-text-off">
          {{ 'Daikin status error' | translate }}
        </div>
      </div>
    </ng-container>
      <!-- 1 Ok -->

    <ng-container
      *ngIf="daikinUnitStatusProps[0]?.value === 1 || daikinUnitStatusProps[0]?.value === '1'"
    >
    <div
    [title]="'Daikin status OK' | translate"
    [ngClass]="[
      displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
    ]"
    class="full-height"
  >
    <div
      class="icon-background-off"
      [ngClass]="[
        'icon-temp font-size19',
        displaySize === 'facility' ? 'facility-icon' : ''
      ]"
    ></div>

    <div *ngIf="displaySize === 'large'" class="status-text-on">
      {{ 'Daikin status OK' | translate }}
    </div>
  </div>
    </ng-container>
     <!-- -1 NC -->
    <ng-container
      *ngIf="daikinUnitStatusProps[0]?.value === -1 || daikinUnitStatusProps[0]?.value === '-1' "
    >
    <div
    [title]="'Daikin status NC' | translate"
    [ngClass]="[
      displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
    ]"
    class="full-height"
  >
    <div
      class="icon-background-off"
      [ngClass]="[
        'icon-temp font-size19',
        displaySize === 'facility' ? 'facility-icon' : ''
      ]"
    ></div>

    <div *ngIf="displaySize === 'large'" class="status-text-off">
      {{ 'Daikin status NC' | translate }}
    </div>
  </div>
    </ng-container>
    <!-- ELSE -->
    <ng-container
    *ngIf="(daikinUnitStatusProps[0]?.value) !== -1 &&
    (daikinUnitStatusProps[0]?.value) !== '-1' &&
     (daikinUnitStatusProps[0]?.value) !== 0 &&
     (daikinUnitStatusProps[0]?.value) !== '0' &&
     (daikinUnitStatusProps[0]?.value) !== 1 &&
    (daikinUnitStatusProps[0]?.value) !== '1'"
  >
  <div
  [title]="'Daikin status undefined' | translate"
  [ngClass]="[
    displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
  ]"
  class="full-height"
>
  <div
    class="icon-background-off"
    [ngClass]="[
      'icon-temp font-size19',
      displaySize === 'facility' ? 'facility-icon' : ''
    ]"
  ></div>

  <div *ngIf="displaySize === 'large'" class="status-text-off">
    {{ 'Daikin status und.' | translate }}
  </div>
</div>
  </ng-container>
  </ng-container>
  </ng-container>

  <!-- DAIKIN UNIT FAN SPEED! -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(daikinUnitFanSpeed)'">
    <ng-container
    *ngIf="
       location.controllers.$values
          | getFirstControllerWithProperty : Property.isDaikinUnitFanSpeed : updated
          | findProperty : Property.isDaikinUnitFanSpeed as daikinUnitFanSpeedProps
    "
  >
  <!-- 0 Error -->
    <ng-container
      *ngIf="daikinUnitFanSpeedProps[0]?.value === 0|| daikinUnitFanSpeedProps[0]?.value === '0'"
    >
      <div
        [title]="'Daikin fan speed low' | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-off"
          [ngClass]="[
            'icon-fan font-size19',
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>

        <div *ngIf="displaySize === 'large'" class="status-text-on">
          {{ 'Daikin fan speed low' | translate }}
        </div>
      </div>
    </ng-container>
      <!-- 1 Ok -->

    <ng-container
      *ngIf="daikinUnitFanSpeedProps[0]?.value === 1 || daikinUnitFanSpeedProps[0]?.value === '1'"
    >
    <div
    [title]="'Daikin fan speed high' | translate"
    [ngClass]="[
      displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
    ]"
    class="full-height"
  >
    <div
      class="icon-background-off"
      [ngClass]="[
        'icon-fan font-size19',
        displaySize === 'facility' ? 'facility-icon' : ''
      ]"
    ></div>

    <div *ngIf="displaySize === 'large'" class="status-text-on">
      {{ 'Daikin fan speed high' | translate }}
    </div>
  </div>
    </ng-container>
     <!-- -1 Unknown -->
    <ng-container
      *ngIf="daikinUnitFanSpeedProps[0]?.value === -1 || daikinUnitFanSpeedProps[0]?.value === '-1'"
    >
    <div
    [title]="'Daikin fan speed unknown' | translate"
    [ngClass]="[
      displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
    ]"
    class="full-height"
  >
    <div
      class="icon-background-off"
      [ngClass]="[
        'icon-fan font-size19',
        displaySize === 'facility' ? 'facility-icon' : ''
      ]"
    ></div>

    <div *ngIf="displaySize === 'large'" class="status-text-off">
      {{ 'Daikin fan speed unk.' | translate }}
    </div>
  </div>
    </ng-container>
    <!-- ELSE -->
    <ng-container
    *ngIf="(daikinUnitFanSpeedProps[0]?.value) !== -1 &&
    (daikinUnitFanSpeedProps[0]?.value) !== '-1' &&
     (daikinUnitFanSpeedProps[0]?.value) !== 0 &&
     (daikinUnitFanSpeedProps[0]?.value) !== '0' &&
     (daikinUnitFanSpeedProps[0]?.value) !== 1 &&
    (daikinUnitFanSpeedProps[0]?.value) !== '1'"
  >
  <div
  [title]="'Daikin fan speed undefined' | translate"
  [ngClass]="[
    displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
  ]"
  class="full-height"
>
  <div
    class="icon-background-off"
    [ngClass]="[
      'icon-fan font-size19',
      displaySize === 'facility' ? 'facility-icon' : ''
    ]"
  ></div>

  <div *ngIf="displaySize === 'large'" class="status-text-off">
    {{ 'Daikin fan speed und.' | translate }}
  </div>
</div>
  </ng-container>
  </ng-container>
  </ng-container>


  <!-- DAIKIN UNIT MODE! -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(daikinUnitMode)'">
    <ng-container
    *ngIf="
       location.controllers.$values
          | getFirstControllerWithProperty : Property.isDaikinUnitMode : updated
          | findProperty : Property.isDaikinUnitMode as daikinUnitModeProps
    "
  >
  <!-- 0 Error -->
    <!-- <ng-container
      *ngIf="Number(daikinUnitFanSpeedProps[0]?.value) === 0"
    >
      <div
        [title]="'Daikin fan speed error' | translate"
        [ngClass]="[
          displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
        ]"
        class="full-height"
      >
        <div
          class="icon-background-on"
          [ngClass]="[
            'icon-temp font-size19',
            displaySize === 'facility' ? 'facility-icon' : ''
          ]"
        ></div>

        <div *ngIf="displaySize === 'large'" class="status-text-on">
          {{ 'Daikin fan speed error' | translate }}
        </div>
      </div>
    </ng-container> -->

    <!-- 1 Fan -->
    <ng-container
      *ngIf="Number(daikinUnitModeProps[0]?.value) === 1"
    >
    <div
    [title]="'Daikin mode fan' | translate"
    [ngClass]="[
      displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row'
    ]"
    class="full-height"
  >
    <div
      class="icon-background-off"
      [ngClass]="[
        'icon-temp font-size19',
        displaySize === 'facility' ? 'facility-icon' : ''
      ]"
    ></div>

    <div *ngIf="displaySize === 'large'" class="status-text-on">
      {{ 'Daikin mode fan' | translate }}
    </div>
  </div>
    </ng-container>
     <!-- 2 Heat -->
    <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 2">
        <div
        [title]="'Daikin mode heat' | translate"
        [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
        class="full-height">
        <div
          class="icon-background-off"
          [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
        ></div>
        <div *ngIf="displaySize === 'large'" class="status-text-on">
          {{ 'Daikin mode heat' | translate }}
        </div>
      </div>
    </ng-container>

    <!-- 4 Cool -->
    <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 4">
      <div
      [title]="'Daikin mode cool' | translate"
      [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
      class="full-height">
      <div
        class="icon-background-off"
        [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
      ></div>
      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Daikin mode cool' | translate }}
      </div>
    </div>
    </ng-container>

     <!-- 16 Set point -->
     <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 16">
      <div
      [title]="'Daikin mode set point' | translate"
      [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
      class="full-height">
      <div
        class="icon-background-off"
        [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
      ></div>
      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Daikin mode set point' | translate }}
      </div>
    </div>
    </ng-container>

    <!-- 32 Ventilation -->
    <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 32">
      <div
      [title]="'Daikin mode ventilation' | translate"
      [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
      class="full-height">
      <div
        class="icon-background-off"
        [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
      ></div>
      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Daikin mode ventilation' | translate }}
      </div>
    </div>
    </ng-container>

    <!-- 64 Dry -->
    <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 64">
      <div
      [title]="'Daikin mode dry' | translate"
      [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
      class="full-height">
      <div
        class="icon-background-off"
        [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
      ></div>
      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Daikin mode dry' | translate }}
      </div>
    </div>
    </ng-container>

    <!-- 256 Auto(Heat) -->
    <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 256">
      <div
      [title]="'Daikin mode auto(heat)' | translate"
      [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
      class="full-height">
      <div
        class="icon-background-off"
        [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
      ></div>
      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Daikin mode auto(heat)' | translate }}
      </div>
    </div>
    </ng-container>

    <!-- 512 Auto(Heat) -->
    <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 512">
      <div
      [title]="'Daikin mode auto(cool)' | translate"
      [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
      class="full-height">
      <div
        class="icon-background-off"
        [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
      ></div>
      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Daikin mode auto(cool)' | translate }}
      </div>
    </div>
    </ng-container>

    <!-- 4096 Unknown -->
    <ng-container *ngIf="Number(daikinUnitModeProps[0]?.value) === 4096">
      <div
      [title]="'Daikin mode unknown' | translate"
      [ngClass]="[displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
      class="full-height">
      <div
        class="icon-background-off"
        [ngClass]="['icon-temp font-size19',displaySize === 'facility' ? 'facility-icon' : '']"
      ></div>
      <div *ngIf="displaySize === 'large'" class="status-text-on">
        {{ 'Daikin mode unknown' | translate }}
      </div>
    </div>
    </ng-container>

    <!-- ELSE -->
    <ng-container
       *ngIf="Number(daikinUnitModeProps[0]?.value) !== 1 && Number(daikinUnitModeProps[0]?.value) !== 2
       && Number(daikinUnitModeProps[0]?.value) !== 4 && Number(daikinUnitModeProps[0]?.value) !== 16
       && Number(daikinUnitModeProps[0]?.value) !== 32 && Number(daikinUnitModeProps[0]?.value) !== 64
       && Number(daikinUnitModeProps[0]?.value) !== 256 && Number(daikinUnitModeProps[0]?.value) !== 512
       && Number(daikinUnitModeProps[0]?.value) !== 4096">
     <div [title]="'Daikin mode undefined' | translate" [ngClass]="[
       displaySize === 'minimal' ? 'flex-row-center-full' : 'flex-row']"
       class="full-height">
     <div
       class="icon-background-off"
       [ngClass]="['icon-temp font-size19', displaySize === 'facility' ? 'facility-icon' : '']"></div>
     <div *ngIf="displaySize === 'large'" class="status-text-off">
       {{ 'Daikin mode und.' | translate }}
     </div>
     </div>
  </ng-container>
  </ng-container>
  </ng-container>


  <!-- CUSTOM (HVAC HEAT COOL AUTO) -->
  <ng-container *ngIf="propToDisplay.mode === 'custom(hvacHeatCoolAuto)'">
    <ng-container
      *ngIf="
      location.controllers.$values
          | findProperty : Property.isHvacHeatCool as hvacHeatCoolProps
      "
    >
      <ng-container
        *ngIf="
        location.controllers.$values
            | findProperty : Property.isHvacHeatCoolAuto as hvacHCAutoProps
        "
      >
        <div
          [ngClass]="[
            displaySize === 'minimal' ? 'status-container-minimal' : ''
          ]"
          class="flex-row-center-v"
        >
          <!-- HVAC HEAT COOL AUTO -->
          <ng-container *ngIf="hvacHCAutoProps.length > 0">
            <ng-container
              *ngIf="displaySize === 'large' || displaySize === 'minimal'"
            >
                <div *ngIf="(hvacHCAutoProps[0]?.value.toString() === '1') ||
                (hvacHCAutoProps[0]?.value.toString() !== '1' &&
                hvacHeatCoolProps[0]?.value.toString() !== '0' &&
                hvacHeatCoolProps[0]?.value.toString() !== '1')"
                class="icon-background-on font-size19 icon-hvac"></div>

                <div *ngIf="
                hvacHCAutoProps[0]?.value.toString() !== '1' &&
                hvacHeatCoolProps[0]?.value.toString() === '0'"
                class="icon-background-on font-size19 icon-sun orange-bg"></div>

                <div *ngIf="
                hvacHCAutoProps[0]?.value.toString() !== '1' &&
                hvacHeatCoolProps[0]?.value.toString() === '1'"
                class="icon-background-on font-size19 icon-snowflake blue-bg"></div>

              <div
                *ngIf="hvacHCAutoProps[0]?.value.toString() === '1'"
                class="status-text-on"
                title="{{ 'Auto' | translate }}"
              >
                {{ displaySize === "large" ? "Auto" : ("A" | translate) }}
              </div>
              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() === '0'
                "
                title="{{ 'Heating' | translate }}"
                class="status-text-on"
              >
                {{ displaySize === "large" ? "Heating" : ("H" | translate) }}
              </div>
              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() === '1'
                "
                title="{{ 'Cooling' | translate }}"
                class="status-text-on"
              >
                {{ displaySize === "large" ? "Cooling" : ("C" | translate) }}
              </div>

              <div
                *ngIf="
                  hvacHCAutoProps[0]?.value.toString() !== '1' &&
                  hvacHeatCoolProps[0]?.value.toString() !== '0' &&
                  hvacHeatCoolProps[0]?.value.toString() !== '1'
                "
                class="status-text-off"
                title="{{ '? Heat/Cool/Auto' | translate }}"
              >
                {{
                  displaySize === "large"
                    ? " ? Heat/Cool/Auto"
                    : ("?" | translate)
                }}
              </div>
            </ng-container>
          </ng-container>
          <!-- HVAC HEAT COOL  -->
          <ng-container
            *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0">
            <ng-container
              *ngIf="hvacHeatCoolProps[0] | propIsActive; else propNotActive">
              <div
              class="icon-background-on font-size19 icon-snowflake blue-bg"
              ></div>
              <div class="status-text-on">
                {{ "Cooling" | translate }}
              </div>
            </ng-container>
            <ng-template #propNotActive>
              <div
                class="icon-background-on font-size19 icon-sun orange-bg"
              ></div>
              <div class="status-text-off">
                {{ "Heating" | translate }}
              </div>
            </ng-template>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
