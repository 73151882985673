import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Controller } from 'src/app/core/models/controller.model';
import { Location } from 'src/app/core/models/project/location.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';

@Component({
  selector: 'app-room-modal-service',
  templateUrl: './room-modal-service.component.html',
  styleUrls: ['./room-modal-service.component.scss'],
})
export class RoomModalServiceComponent implements OnInit {
  @Input() controllers: Controller[];
  locations$: Observable<Location[]> = this.projectService.getFilteredLocations();
  selectedLocation: Location;
  selectedController: Controller;

  constructor(
    private projectService: ProjectService,
    private changeDetectionRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //TODO
    // this.apiProjectService.getFilteredLocations().subscribe();
  }

  selectLocation(locationId: number) {
    this.selectedLocation = null;
    this.selectedController = null;
    this.changeDetectionRef.detectChanges();
    this.locations$.pipe(take(1)).subscribe((locations) => {
      this.selectedLocation = locations.find((loc: Location) => {
        return loc.locationId === locationId;
      });
    });
  }

  selectController(controller: Controller) {
    this.selectedLocation = null;
    this.selectedController = null;
    this.changeDetectionRef.detectChanges();
    this.selectedController = controller;
  }

  cancelSelection() {
    this.selectedLocation = null;
    this.selectedController = null;
  }
}
