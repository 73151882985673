import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DefaultsPreset, FrontendSettings } from './http/api-settings.service';
import { DisplayConfig, DisplayPropertyParams } from 'src/app/shared/components/display-property/display-property.component';
import { map } from 'rxjs/operators';
import { User } from 'src/app/core/models/user/user.model';
import { Controller } from 'src/app/core/models/controller.model';
import { SettingsPropData } from 'src/app/shared/components/room-modal-settings/room-modal-settings.component';

export interface CheckInOutTime {
  hour: number;
  minute: number
}

export interface StoredView {
  userId: number;
  view: DisplayConfig;
}

export interface GuestStayShortcut {
  userId: number;
  active: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private presets$ = new BehaviorSubject<DefaultsPreset[]>([])
    // private defaultCheckoutTime = new BehaviorSubject<CheckoutTime>({hour: 12, minute: 0})
    private frontendSettings$ = new BehaviorSubject<FrontendSettings>(null);
    private filteredPropertyDisplayConfigs$ = new BehaviorSubject<DisplayConfig[]>(null);
    private selectedPropertyDisplayConfig$  = new BehaviorSubject<DisplayConfig>(null);
    private guestStayShortcut$ = new BehaviorSubject<GuestStayShortcut[]>(null);

    loadedViewForUserId: number = undefined;
    selectedViewsLocalStorageKey = 'irooms_selected_views';
    guestStayShortcut = 'irooms_guest_stay';

    constructor() {}

    getDefaultPresets() {
      return this.presets$.asObservable();
    }

    setDefaultPresets(presets: DefaultsPreset[]) {
      this.presets$.next(presets);
    }


    getUseBalconyLights(): Observable<boolean> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.useBalconyLightsSchedule;
      }))
    }

    getRoomModalPropSettings(): Observable<{ room: SettingsPropData[], bathroom: SettingsPropData[]}> {
      return this.frontendSettings$.pipe(map((frontendSettings: FrontendSettings) => {
        return frontendSettings.settings.roomModalPropSettings;
      }))
    }

    getRequiredAlarmText(): Observable<boolean> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings?.settings.requireAckAlarmText;
      }))
    }

    getDefaultCheckoutTime(): Observable<CheckInOutTime> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.defaultCheckoutTime;
      }))
    }

    getDefaultCheckinTime(): Observable<CheckInOutTime> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.defaultCheckInTime;
      }))
    }

    getDefaultStayDuration(): Observable<number> {
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.defaultStayDurationDays;
      }))
    }

    /* getPropertyDisplayConfigs(): Observable<DisplayConfig[]> {
      // return this.propertyDisplayConfigs$.asObservable();
      return this.frontendSettings$.pipe(map((frontendSettings) => {
        return frontendSettings.settings.propertyDisplayConfigs;
      }))
    } */

    getFilteredPropertyDisplayConfigs(): Observable<DisplayConfig[]> {
       return this.filteredPropertyDisplayConfigs$.asObservable();
    }

    setFrontendSettings(frontendSettings: FrontendSettings) {
      this.frontendSettings$.next(frontendSettings);
    }

    getFrontendSettings() {
      return this.frontendSettings$.asObservable();
    }

    getSelectedPropertyDisplayConfig() {
      return this.selectedPropertyDisplayConfig$.asObservable();
    }

    setSelectedPropertyDisplayConfig(singlePropDisplayConfig: DisplayConfig, activeUser: User) {
      this.storeSelectedView(singlePropDisplayConfig, activeUser);
      this.selectedPropertyDisplayConfig$.next(singlePropDisplayConfig)
    }




    /* setupViewConfigs(controllers: Controller[]) {
      const viewConfigs: DisplayConfig[] = this.frontendSettings$.getValue().settings.propertyDisplayConfigs;
      const filteredConfigs = this.filterViewConfigs(controllers, viewConfigs)
      this.filteredPropertyDisplayConfigs$.next(filteredConfigs);
      this.selectedPropertyDisplayConfig$.next(filteredConfigs[0]);
    } */


    setupViewConfigs() {
      const viewConfigs: DisplayConfig[] = this.frontendSettings$.getValue().settings.propertyDisplayConfigs;
      // const filteredConfigs = this.filterViewConfigs(controllers, viewConfigs)
      this.filteredPropertyDisplayConfigs$.next(viewConfigs);
      this.selectedPropertyDisplayConfig$.next(viewConfigs[0]);
    }


    filterViewConfigs(controllers: Controller[], usedViewConfigs: DisplayConfig[]) {
      return usedViewConfigs.map((config: DisplayConfig)=> {
        return {
          name: config.name,
          // permissionRequired: config.permissionRequired,
          data: this.filterUnusedConfigData(controllers, config.data)
        }
      })
    }

    filterUnusedConfigData(controllers: Controller[], displayData: DisplayPropertyParams[]) : DisplayPropertyParams[] {
      return displayData.filter((propParam: DisplayPropertyParams)=> {
        if (propParam.mode === 'status') {
          return true
        }
        return Controller.isPropertyInControllers(controllers, propParam.propInfo.codeFrom, propParam.propInfo.codeTo)
      })
    }



    storeSelectedView(singlePropDisplayConfig: DisplayConfig, activeUser: User) {
      const storedSelectedViewsString: string = localStorage.getItem(this.selectedViewsLocalStorageKey);
      const newStoredView: StoredView = {
        userId: activeUser.userId,
        view: singlePropDisplayConfig
        }
      if (storedSelectedViewsString === null) {
        const storedView: StoredView [] = [newStoredView]
        localStorage.setItem(this.selectedViewsLocalStorageKey, JSON.stringify(storedView));
      } else {
        const storedSelectedViews: StoredView [] = JSON.parse(storedSelectedViewsString);
        const newStoredViews = storedSelectedViews.filter((storedView) => storedView.userId !== activeUser.userId)
        newStoredViews.push(newStoredView);
        localStorage.setItem(this.selectedViewsLocalStorageKey, JSON.stringify(newStoredViews));
      }
    }

    initializeViewFromStorage(user: User) {
      if (this.loadedViewForUserId !== user.userId) {
        const storedSelectedViews: StoredView [] = JSON.parse(localStorage.getItem(this.selectedViewsLocalStorageKey));
        const storedSelectedViewForUser = storedSelectedViews?.find(
          (storedView: StoredView) => Number(storedView.userId) === Number(user.userId))
        if (storedSelectedViewForUser) {
          this.selectedPropertyDisplayConfig$.next(storedSelectedViewForUser.view);
          this.loadedViewForUserId = user.userId;
        } else {
          this.setSelectedPropertyDisplayConfig(this.filteredPropertyDisplayConfigs$.getValue()[0], user);
            // this.selectedPropertyDisplayConfig$.next(firstConfigWithPermission);

        }
      }
    }

    initializeGuestStayShortcut(user: User) {
      let guestStayShortcutData: GuestStayShortcut[] = JSON.parse(localStorage.getItem(this.guestStayShortcut))
      const userSetup: GuestStayShortcut = {
        userId: user.userId,
        active: true
        }

      if (guestStayShortcutData) {
        const activeUser = guestStayShortcutData.find( (data: GuestStayShortcut) => data.userId == user.userId)
        if (activeUser === undefined) {
          guestStayShortcutData.push(userSetup);
          localStorage.setItem(this.guestStayShortcut, JSON.stringify(guestStayShortcutData));
        }
      } else {
        const storeValue: GuestStayShortcut [] = [userSetup]
        guestStayShortcutData = [];
        guestStayShortcutData.push(userSetup)
        localStorage.setItem(this.guestStayShortcut, JSON.stringify(storeValue));
      }

      this.guestStayShortcut$.next(guestStayShortcutData)
    }

    setGuestStayShortcut(user: User, value: boolean) {
      const guestStayShortcutData: GuestStayShortcut[] = JSON.parse(localStorage.getItem(this.guestStayShortcut))
      const activeUser = guestStayShortcutData.find( (data: GuestStayShortcut) => data.userId == user.userId)
      activeUser.active = value;
      this.guestStayShortcut$.next(guestStayShortcutData);
      localStorage.setItem(this.guestStayShortcut, JSON.stringify(guestStayShortcutData));
    }

    getGuestStayShortcutForUser() {
      return this.guestStayShortcut$.asObservable()
    }

    /* setFirstPropertyDisplayConfigWithPermission(user: User) {
      const firstConfigWithPermission = this.filteredPropertyDisplayConfigs$.getValue().find((config: DisplayConfig)=> {
        return user.havePermission(config.permissionRequired)
      })
      this.setSelectedPropertyDisplayConfig(firstConfigWithPermission, user);
      // this.selectedPropertyDisplayConfig$.next(firstConfigWithPermission);
    } */
}
