<ng-container
  *ngIf="controllers | findProperty : Property.isDaikinUnitFanSpeed: updated as props"
>
<div
*ngIf="forcePropIndex===-1">
  <div
    *ngFor="let prop of props"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <!-- 0 Low -->
    <ng-container *ngIf="prop?.value === 0 || prop?.value === '0'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin fan speed low" | translate }}</div>
    </ng-container>
    <!-- 1 high -->
    <ng-container *ngIf="prop?.value === 1 || prop?.value === '1'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ 'Daikin fan speed high' | translate }}</div>
    </ng-container>
    <!-- -1 unknown -->

    <ng-container *ngIf="prop?.value === -1 || prop?.value === '-1'">
      <div class="icon-temp icon-background-off font-size19"></div>
      <div class="status-text-off">{{ 'Daikin fan speed unknown' | translate }}</div>
    </ng-container>
    <!-- ELSE -->
    <ng-container
      *ngIf="
        prop?.value !== -1 &&
        prop?.value !== '-1' &&
        prop?.value !== 0 &&
        prop?.value !== '0' &&
        prop?.value !== 1 &&
        prop?.value !== '1'
      "
    >
      <div class="icon-fan icon-background-off font-size19"></div>
      <div class="status-text-off">{{ 'Daikin fan speed undefined' | translate }}</div>
    </ng-container>
  </div>
</div>
<div
*ngIf="forcePropIndex!=-1"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <!-- 0 Low -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 0 || props[forcePropIndex]?.value === '0'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin fan speed low" | translate }}</div>
    </ng-container>
    <!-- 1 high -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 1 || props[forcePropIndex]?.value === '1'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ 'Daikin fan speed high' | translate }}</div>
    </ng-container>
    <!-- -1 unknown -->

    <ng-container *ngIf="props[forcePropIndex]?.value === -1 || props[forcePropIndex]?.value === '-1'">
      <div class="icon-temp icon-background-off font-size19"></div>
      <div class="status-text-off">{{ 'Daikin fan speed unknown' | translate }}</div>
    </ng-container>
    <!-- ELSE -->
    <ng-container
      *ngIf="
      props[forcePropIndex]?.value !== -1 &&
      props[forcePropIndex]?.value !== '-1' &&
      props[forcePropIndex]?.value !== 0 &&
      props[forcePropIndex]?.value !== '0' &&
      props[forcePropIndex]?.value !== 1 &&
      props[forcePropIndex]?.value !== '1'
      "
    >
      <div class="icon-fan icon-background-off font-size19"></div>
      <div class="status-text-off">{{ 'Daikin fan speed undefined' | translate }}</div>
    </ng-container>
  </div>
</ng-container>
