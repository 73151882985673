import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Controller } from 'src/app/core/models/controller.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { Location, Tag } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { RoomTagPopoverComponent } from 'src/app/shared/components/room-tag-popover/room-tag-popover.component';

@Component({
  selector: 'app-hvac-common-area-modal-view',
  templateUrl: './hvac-common-area-modal-view.component.html',
  styleUrls: ['./hvac-common-area-modal-view.component.scss']
})
export class HvacCommonAreaModalViewComponent implements OnInit {
  @Input() location: Location;
  controllers: Controller[];
  allTags: Tag[] = []


  isRoomTemperatureCurrent = Property.isRoomTemperatureCurrent;
  isRoomTemperatureSet = Property.isRoomTemperatureSet;
  // isHVACLargeTempDiff = Property.isHVACLargeTempDiff;
  isHvacHeatCool = Property.isHvacHeatCool
  isHvacHeatCoolAuto = Property.isHvacHeatCoolAuto
  isHVACLargeTempDiffRoom = Property.isHVACLargeTempDiffRoom;
  isHVACLargeTempDiffBathroom = Property.isHVACLargeTempDiffBathroom;
  // isHvacHeatingCooling = Property.isHvacHeatingCooling;


  isHvacActive = Property.isHvacActive;
  isFancoilV1 = Property.isFancoilV1;
  isFancoilV2 = Property.isFancoilV2;
  isFancoilV3 = Property.isFancoilV3;

  isCommunicationError = Property.isCommunicationError;
  isWindowOpened = Property.isWindowOpened;
  isWindowOpenTooLongAlarm = Property.isWindowOpenTooLongAlarm;

  SoftwarePermissionId = SoftwarePermissionId
  signedInUser: User;

  constructor(
    private apiProjectService: ApiProjectService,
    private popoverController: PopoverController,
    private currentUserStoreService: CurrentUserStoreService
    ) { }

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
    this.apiProjectService.getAllTags().subscribe( value => {
      this.allTags = value;
    })
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
  }

  clearStatus() {
    // to clear status of /safety/sos,   /security/burglary, and  /security/safe/burglary,
    //  send any true value to any of those endpoints
    let commError: string;
    let commErrorid: number;
    let commErrorValue;


    this.controllers.forEach((controller: Controller) => {
      controller.controllerProperties.$values.forEach((prop)=> {
        if(Property.isCommunicationError(prop)) {
          commError = controller.designation;
          commErrorid = prop.id;
          commErrorValue = prop.value;
        }
      })
      }
    );

      if(Number(commErrorValue)) {
        this.apiProjectService.changeProperty(commError, commErrorid, 1).subscribe();
      }
  }

  getHvacActiveMode() {
    const target = this.controllers[0].controllerProperties.$values.find(prop => {
      if (Property.fancoilMode(prop) && typeof (+prop.value) === 'number'  && prop.value !== '') {
        return true;
      }
    })

    if(+target.value === 0) {
      return 'i-hvac-off';
    } else if (+target.value === 1) {
      return 'icon-hvac-low font-size15';
    } else if(+target.value === 2) {
      return 'icon-hvac-mid font-size15';
    } else if (+target.value === 3) {
      return 'icon-hvac-high font-size15';
    } else if (+target.value === 6) {
      return 'i-hvac-auto'
    }
  }

  async editControllerTags(ev) {
    const popover = await this.popoverController.create({
      component: RoomTagPopoverComponent,
      cssClass: 'tags-popover',
      event: ev,
      showBackdrop: false,
      componentProps: {
        location: this.location,
        existingTags: this.allTags
      }
    });
    await popover.present();

    popover.onDidDismiss().then( () => {
    })
  }


}
