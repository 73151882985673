import { Pipe, PipeTransform } from '@angular/core';
import { UserSettingsService } from '../services/user-settings.service';

@Pipe({
    name: 'customTranslatePipe'
})
export class CustomTranslatePipe implements PipeTransform {

    wordPairs = [
    { en: 'On', hr: 'Uključeno' },
    { en: 'Off', hr: 'Isključeno' },
    { en: 'Moving', hr: 'Pokreću se' },
    { en: 'Not moving', hr: 'Ne pokreću se' },
    { en: 'Opened', hr: 'Otvoreno' },
    { en: 'Low', hr: 'Sporo' },
    { en: 'Mid', hr: 'Srednje' },
    { en: 'High', hr: 'Brzo' },
    { en: 'Auto', hr: 'Auto' },
    { en: 'Medium', hr: 'Srednje' },
    { en: 'Alarm', hr: 'Alarm' },
    { en: 'Normal', hr: 'Normalno' },
    { en: 'Heating', hr: 'Grijanje' },
    { en: 'Cooling', hr: 'Hlađenje' },
    { en: 'Open', hr: 'Otvoreno' },
    { en: 'Closed', hr: 'Zatvoreno' },
    { en: 'Locked', hr: 'Zaključano' },
    { en: 'Unlocked', hr: 'Otključano' },
    { en: 'Bathroom', hr: 'Kupaona' },
    { en: 'Time', hr: 'Vrijeme' },
    { en: 'Values', hr: 'Vrijednosti' },
    { en: 'Blinds', hr: 'Žaluzine' },
    { en: 'Power', hr: 'Snaga' },
    { en: 'Hvac control', hr: 'Kontrola hvac' },
    { en: 'to', hr: 'je' },
    { en: 'than', hr: 'od' },
    { en: 'from', hr: 'od' },
    { en: 'Equal', hr: 'Jednaki' },
    { en: 'Less', hr: 'Manji' },
    { en: 'Greater', hr: 'Veći' },
    { en: 'Less or equal', hr: 'Manji ili jednaki' },
    { en: 'Greater or equal', hr: 'Veći ili jednaki' },
    { en: 'In range', hr: 'U dometu' },
    { en: 'In range lower included', hr: 'U dometu s manjim uključenim' },
    { en: 'In range upper included', hr: 'U dometu s većim uključenim' },
    { en: 'In range both included', hr: 'U dometu s oba uključena' },
    { en: 'Press back button again to exit app.', hr: 'Opet pritisnite nazad da bi izašli iz aplikacije.' },
    { en: 'Enter ABAS code', hr: 'Unesite ABAS kod' },
    { en: 'Cancel', hr: 'Odustani' },
    { en: 'Enter', hr: 'Prihvati' },
    { en: 'Please wait...', hr: 'Molimo Vas pričekajte...' },
    { en: 'Invalid ABAS code. Please try again.', hr: 'Netočan ABAS kod. Molimo Vas pokušajte ponovno.' },
    { en: 'Error connecting to ABAS server', hr: 'Dogodila se greška u spajanju na ABAS server.' },
    { en: 'Acknowledge all alarms?', hr: 'Potvrdite sve alarme?' },
    { en: 'Enter confirmation text', hr: 'Unesite potvrdni tekst' },
    { en: 'Acknowledge alarm?', hr: 'Potvrdite alarm?' },
    { en: 'Your session has expired. Please login again to continue', hr: 'Vaša sesija je istekla. Molimo Vas prijavite se ponovno da biste nastavili.' },
    { en: 'Wrong username or password/pin', hr: 'Netočno korisničko ime ili lozinka/pin' },
    { en: 'Pin you entered is wrong!', hr: 'Uneseni pin je netočan!' },
    { en: 'No connection', hr: 'Nema konekcije' },
    { en: 'Invalid data', hr: 'Nevažeći podaci' },
    { en: 'Access denied!', hr: 'Pristup odbijen!' },
    { en: 'Access denied. Please login', hr: 'Pristup odbijen. Molimo Vas prijavite se' },
    { en: 'Confirm delete', hr: 'Potvrdi brisanje' },
    { en: 'Confirm', hr: 'Potvrdi' },
    { en: 'Delete', hr: 'Izbriši' },
    { en: 'Delete preset', hr: 'Izbriši postavke' },
    { en: 'And', hr: 'I' },
    { en: 'Or', hr: 'Ili' },
    { en: 'And not', hr: 'I ne' },
    { en: 'Not affecting', hr: 'Ne utječe' },
    { en: 'Activate mode', hr: 'Aktiviraj režim rada' },
    { en: 'Deactivate mode', hr: 'Deaktiviraj režim rada' },
    { en: 'Automation is not editable', hr: 'Automatizacija nije editabilna' },
    { en: 'Delete automation', hr: 'Izbriši automatizaciju' },
    { en: 'Discard', hr: 'Odbaci' },
    { en: 'Delete mode', hr: 'Izbriši režim rada' },
    { en: 'Discarding changes', hr: 'Odbaci promjene' },
    { en: 'Discard', hr: 'Odbaci' },
    { en: 'Delete scene', hr: 'Izbriši scenu' },
    { en: 'Delete alarm subscription?', hr: 'Izbrisati pretplatu na alarm?' },
    { en: 'Delete configuration and return to landing page?', hr: 'Izbrisati konfiguraciju i vratiti se na početak?' },
    { en: 'Deleting configuration...', hr: 'Brišem konfiguraciju...' },
    { en: 'Delete user', hr: 'Izbriši korisnika' },
    { en: 'is equal to ', hr: 'je jednak ' },
    { en: 'is less than ', hr: 'je manji od ' },
    { en: 'is greater than ', hr: 'je veći od ' },
    { en: 'is less or equal to ', hr: 'je manji ili jednak s ' },
    { en: 'is greater or equal to ', hr: 'je veći ili jednak s ' },
    { en: 'is in range between ', hr: 'je u dometu ' },
    { en: 'is equal or greater than ', hr: 'je jednak ili veći od ' },
    { en: ' and less than ', hr: ' i manji od ' },
    { en: ' and less or equal to ', hr: ' i manji ili jednak s ' },
    { en: ' and ', hr: ' i ' },
    { en: 'Yes', hr: 'Da' },
    { en: 'No', hr: 'Ne' },
    { en: 'Heating', hr: 'Grijanje' },
    { en: 'Cooling', hr: 'Hlađenje' },
    { en: 'Someone in house', hr: 'Netko je u kući' },
    { en: 'Custom', hr: 'Prilagodi' },
    { en: 'Light', hr: 'Svjetlo' },
    { en: 'Status', hr: 'Stanje' },
    { en: 'Set light to', hr: 'Postavi jačinu na' },
    { en: 'full', hr: 'sve' },
    { en: 'compact', hr: 'skupljeno' },
    { en: 'minimal', hr: 'minimalno' },
    { en: 'No preset for active (cooling) mode.', hr: 'Nema postavki za aktivni (hlađenje) način rada.' },
    { en: 'No preset for active (heating) mode.', hr: 'Nema postavki za aktivni (grijanje) način rada.' },
    { en: 'Create a preset to apply settings', hr: 'Napravite postavke za primjenu' },
    { en: 'Confirm reset to default settings', hr: 'Potvrdi reset na početne postavke.' },
    { en: 'Reset to default settings?', hr: 'Reset na početne postavke?' },
    { en: 'Burglary detected', hr: 'Detektirana provala' },
    { en: 'Safe comm error', hr: 'Pogreška kom. s sefom' },
    { en: 'HVAC - Large temp diff', hr: 'HVAC - Velika temp razl' },
    { en: 'Large temp. diff. room', hr: 'Velika temp. razl. soba' },
    { en: 'Large temp. diff. bathroom', hr: 'Velika temp. razl. kupaona' },
    { en: 'Flood', hr: 'Poplava' },
    { en: 'PIR - Burglary detected', hr: 'PIR - Detektirana provala' },
    { en: 'SOS', hr: 'SOS' },
    { en: 'Door open too long', hr: 'Predugo otvorena vrata' },
    { en: 'Win open too long', hr: 'Predugo otvoren prozor' },
    { en: 'Delete card?', hr: 'Izbrisati karticu?' },
    { en: 'Delete encoder?', hr: 'Izbrisati enkoder?' },
    { en: 'Accept', hr: 'Prihvati' },
    { en: 'Do you want to download ', hr: 'Želite li preuzeti ' },
    { en: ' file?', hr: ' datoteku?' },
    { en: 'Maximum event number is 5000', hr: 'Maksimalni broj zapisa je 5000' },
    { en: 'Delete role', hr: 'Izbriši rolu' },
    { en: 'Permission required. You do not have permission to activate preset.', hr: 'Potrebno dopuštenje. Nemate dopuštenje za aktivaciju postavki' },
    { en: 'You can click on the floor name to select the entire floor. You can also drag to select multiple rooms at once. Use CTRL + click to add individual rooms or SHIFT + drag to add multiple rooms at once.',
    hr: 'Kliknite na ime kata za odabir cijelog kata. Možete povući kursor da biste odabrali više soba odjednom. Koristite tipku CTRL + klik za dodavanje pojedinačnih soba ili SHIFT + povlačenje za dodavanje više soba odjednom.'},
    { en: 'Undefined', hr: 'Neodređeno' },
    { en: 'Cancel', hr: 'Poništi' },
    { en: 'Confirm', hr: 'Potvrdi' },
    { en: 'Activate heating mode', hr: 'Aktiviraj režim rada grijanje' },
    { en: 'Activate cooling mode', hr: 'Aktiviraj režim rada hlađenje' },
    { en: 'Activate auto mode', hr: 'Aktiviraj režim rada automatski' },
    { en: 'New group', hr: 'Nova grupa' },
    { en: 'Delete floor', hr: 'Izbriši kat' },
    { en: 'New floor', hr: 'Novi kat' },
    { en: 'Not rented', hr: 'Nije iznajmljeno' },
    { en: 'Confirmation text required!', hr: 'Obavezan tekst potvrde!' },
    { en: 'Link copied to clipboard', hr: 'Poveznica kopirana u međuspremnik' },
    { en: 'Link sent to guest e-mail', hr: 'Poveznica poslana gostu na e-mail' },
    { en: 'No available space for cards in that room!', hr: 'Nema slobodnog prostora za kartice u toj sobi!'},
    { en: 'No room found!', hr: 'Soba nije pronađena!'},
    ];

    constructor(private userSettingsService: UserSettingsService) { }
    transform(value: string): string {
        if (this.userSettingsService.getLanguage() === 'hr') {
            const target = this.wordPairs.find(element => {
                if (element.en === value) { return true; }
            });
            if (target) {
                return target.hr;
            } else {
                return value;
            }
        } else {
            return value;
        }
    }
}
