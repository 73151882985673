import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './pages/login/login.component';
import { httpInterceptorProviders } from './core/interceptors';
import { ENVIRONMENT_MQTT_SERVICE_OPTIONS } from 'src/environments/environment';
import { ConfigLoaderService } from './core/app-load/config-loader.service';
import { VersionCheckService } from './core/app-load/version-check.service';
import { IonicModule } from '@ionic/angular';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { UrlService } from '../app/shared/services/url.service'

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CardLoginComponent } from './pages/card-login/card-login.component';
import { LicenseComponent } from './pages/license/license.component';
import { ProjectIdInputComponent } from './pages/project-id-input/project-id-input.component';
import { RestrictedAccessComponent } from './pages/restricted-access/restricted-access.component';

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = ENVIRONMENT_MQTT_SERVICE_OPTIONS;
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CardLoginComponent,
    LicenseComponent,
    ProjectIdInputComponent,
    RestrictedAccessComponent
    ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    DragToSelectModule.forRoot(),
    IonicModule.forRoot({
      platform: {
        /* The default `desktop` function returns false for devices with a touchscreen.
        * This is not always wanted, so this function tests the User Agent instead.
        */
        desktop: (win) => {
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
          return !isMobile;
        }
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigLoaderService) => () => configService.loadConfiguration(),
      deps: [ConfigLoaderService],
      multi: true
    },
    /* {
      provide: APP_INITIALIZER,
      useFactory: (cardReaderService: CardReaderService) => () => cardReaderService.initialize(),
      deps: [CardReaderService],
      multi: true
    }, */
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (versionCheckService: VersionCheckService) => () => versionCheckService.initVersionCheck(),
    //   deps: [VersionCheckService],
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: (UrlService: UrlService) => () => UrlService.startUrlChangeDetection(),
      deps: [UrlService],
      multi: true
    },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
