import { Injectable } from '@angular/core';
import { IMqttMessage } from 'ngx-mqtt';
import { MqttProjectService } from './mqtt-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MqttMessageQueueService {
  private ignoreMqttMessages = false;
  private mqttMessageQueue: IMqttMessage[] = [];
  private queueLength$ = new BehaviorSubject<number>(0)


  constructor(private projectService: ProjectService) {
  }

  async processQueue() {
    while (!this.ignoreMqttMessages) {
      if (this.mqttMessageQueue.length>0) {
        const msg: IMqttMessage = this.mqttMessageQueue.shift();
        this.setQueueLength(this.mqttMessageQueue.length)
        this.handleMqttMessage(msg);
      }
      // sleep
      await new Promise(r => setTimeout(r, 2));
    }
  }

  public addMqttMessageToQueue(mqttMessage: IMqttMessage) {
    if (this.ignoreMqttMessages) {
      return;
    }
    this.mqttMessageQueue.push(mqttMessage);
    this.setQueueLength(this.mqttMessageQueue.length)
  }


  private handleMqttMessage(mqttMessage: IMqttMessage) {
    this.projectService.updateFilteredProjectByMqtt(mqttMessage);
    this.projectService.updateFullProjectByMqtt(mqttMessage)
  }

  setIgnoreMqttMessages(value: boolean) {
    this.ignoreMqttMessages = value;
  }


  setQueueLength(value: number) {
    this.queueLength$.next(value)
  }

  getQueueLength() {
    return this.queueLength$.asObservable();
  }

  emptyMqttQueue() {
    this.mqttMessageQueue = [];
    this.setQueueLength(0);
  }

}
