<ng-container
  *ngIf="controllers | findProperty : Property.isDaikinUnitMode: updated as props"
><div
*ngIf="forcePropIndex===-1">
  <div
    *ngFor="let prop of props"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <!-- 1 Fan -->
    <ng-container *ngIf="prop?.value === 1 || prop?.value === '1'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode fan" | translate }}</div>
    </ng-container>
    <!-- 2 - Heat -->
    <ng-container *ngIf="prop?.value === 2 || prop?.value === '2'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode heat" | translate }}</div>
    </ng-container>
    <!--4 - Cool-->
    <ng-container *ngIf="prop?.value === 4 || prop?.value === '4'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode cool" | translate }}</div>
    </ng-container>
    <!--16 - Set point -->
    <ng-container *ngIf="prop?.value === 16 || prop?.value === '16'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode set point" | translate }}</div>
    </ng-container>
    <!-- 32 - Ventilation
 -->
    <ng-container *ngIf="prop?.value === 32 || prop?.value === '32'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode ventilation" | translate }}</div>
    </ng-container>
    <!-- 64 - Dry -->
    <ng-container *ngIf="prop?.value === 64 || prop?.value === '64'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode dry" | translate }}</div>
    </ng-container>
    <!-- 256 - Auto(Heat) -->
    <ng-container *ngIf="prop?.value === 256 || prop?.value === '256'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode Auto (Heat)" | translate }}</div>
    </ng-container>
    <!-- 512 - Auto(Cool) -->
    <ng-container *ngIf="prop?.value === 512 || prop?.value === '512'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode Auto (cool)" | translate }}</div>
    </ng-container>
    <!-- 4096 - Unknown -->
    <ng-container *ngIf="prop?.value === 4096 || prop?.value === '4096'">
      <div class="icon-temp icon-background-off font-size19"></div>
      <div class="status-text-off">{{ "Daikin op. mode unknown" | translate }}</div>
    </ng-container>

    <!-- ELSE -->
    <ng-container
      *ngIf="
        prop?.value !== 1 &&
        prop?.value !== '1' &&
        prop?.value !== 2 &&
        prop?.value !== '2' &&
        prop?.value !== 4 &&
        prop?.value !== '4' &&
        prop?.value !== 16 &&
        prop?.value !== '16' &&
        prop?.value !== 32 &&
        prop?.value !== '32' &&
        prop?.value !== 64 &&
        prop?.value !== '64' &&
        prop?.value !== 256 &&
        prop?.value !== '256' &&
        prop?.value !== 512 &&
        prop?.value !== '512' &&
        prop?.value !== 4096 &&
        prop?.value !== '4096'
      "
    >
      <div class="icon-temp icon-background-off font-size19"></div>
      <div class="status-text-off">{{'Daikin op. mode undefined' | translate }}</div>
    </ng-container>
  </div>
</div>
<div
*ngIf="forcePropIndex!=-1"
    class="status-container-small flex-row-center-v margin-bottom12 width230"
  >
    <!-- 1 Fan -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 1 || props[forcePropIndex]?.value === '1'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode fan" | translate }}</div>
    </ng-container>
    <!-- 2 - Heat -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 2 || props[forcePropIndex]?.value === '2'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode heat" | translate }}</div>
    </ng-container>
    <!--4 - Cool-->
    <ng-container *ngIf="props[forcePropIndex]?.value === 4 || props[forcePropIndex]?.value === '4'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode cool" | translate }}</div>
    </ng-container>
    <!--16 - Set point -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 16 || props[forcePropIndex]?.value === '16'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode set point" | translate }}</div>
    </ng-container>
    <!-- 32 - Ventilation
 -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 32 || props[forcePropIndex]?.value === '32'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode ventilation" | translate }}</div>
    </ng-container>
    <!-- 64 - Dry -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 64 || props[forcePropIndex]?.value === '64'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode dry" | translate }}</div>
    </ng-container>
    <!-- 256 - Auto(Heat) -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 256 || props[forcePropIndex]?.value === '256'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode Auto (Heat)" | translate }}</div>
    </ng-container>
    <!-- 512 - Auto(Cool) -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 512 || props[forcePropIndex]?.value === '512'">
      <div class="icon-temp icon-background-on font-size19"></div>
      <div class="status-text-on">{{ "Daikin op. mode Auto (cool)" | translate }}</div>
    </ng-container>
    <!-- 4096 - Unknown -->
    <ng-container *ngIf="props[forcePropIndex]?.value === 4096 || props[forcePropIndex]?.value === '4096'">
      <div class="icon-temp icon-background-off font-size19"></div>
      <div class="status-text-off">{{ "Daikin op. mode unknown" | translate }}</div>
    </ng-container>

    <!-- ELSE -->
    <ng-container
      *ngIf="
      props[forcePropIndex]?.value !== 1 &&
      props[forcePropIndex]?.value !== '1' &&
      props[forcePropIndex]?.value !== 2 &&
      props[forcePropIndex]?.value !== '2' &&
      props[forcePropIndex]?.value !== 4 &&
      props[forcePropIndex]?.value !== '4' &&
      props[forcePropIndex]?.value !== 16 &&
      props[forcePropIndex]?.value !== '16' &&
      props[forcePropIndex]?.value !== 32 &&
      props[forcePropIndex]?.value !== '32' &&
      props[forcePropIndex]?.value !== 64 &&
      props[forcePropIndex]?.value !== '64' &&
      props[forcePropIndex]?.value !== 256 &&
      props[forcePropIndex]?.value !== '256' &&
      props[forcePropIndex]?.value !== 512 &&
      props[forcePropIndex]?.value !== '512' &&
      props[forcePropIndex]?.value !== 4096 &&
      props[forcePropIndex]?.value !== '4096'
      "
    >
      <div class="icon-temp icon-background-off font-size19"></div>
      <div class="status-text-off">{{'Daikin op. mode undefined' | translate }}</div>
    </ng-container>
  </div>
</ng-container>
