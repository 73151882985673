<ion-content *ngIf="location; else loading"  [ngClass]="{offline: !location.isOnline}">
<ng-container>
  <div class="flex-row-center-v margin-left20 margin-top10">
    <div *ngIf="!roomIsJoined; else roomJoined" class="room-name"  [ngClass]="{
      'inactive-icon' : !location.isOnline,
      'font-size50': location.name.length <= 5,
      'font-size20': location.name.length > 6 && location.name.length < 15,
      'font-size16': location.name.length  > 15}">{{location.name}}</div>
    <ng-template #roomJoined>
      <!-- we need to fix css for room joined name #todo -->
      <div class="room-name-joined" [ngClass]="[!location.isOnline ? 'inactive-icon' : '']">{{location.controllers.$values | getJoinedControllersName}}</div>
    </ng-template>
    <ion-item class="margin-left80 width40">
      <ion-segment [(ngModel)]="activeView" (ngModelChange)="tabClicked($event)" >
        <ion-segment-button *ngIf="!Location.isAccessControl(location) && !Location.isIOCommonArea(location)" class="width100" value="view">
          <ion-label class="ion-text-wrap">{{'View' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="signedInUser.havePermission(swPermissions.LocationSettings)" class="width100" value="settings">
          <ion-label *ngIf="Location.isIOCommonArea(location) || Location.isAccessControl(location)" class="ion-text-wrap">{{'View and Settings' | translate}}</ion-label>
          <ion-label *ngIf="!(Location.isIOCommonArea(location) || Location.isAccessControl(location))" class="ion-text-wrap">{{'Settings' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="!Location.isIOCommonArea(location) && !Location.isHvacCommonArea(location)" class="width100" value="cards">
          <ion-label class="ion-text-wrap">{{'Cards' | translate}}</ion-label>
        </ion-segment-button>

        <ion-segment-button *ngIf="
        (signedInUser | userHasPermission : swPermissions.AccessLogView) ||
        (signedInUser | userHasPermission : swPermissions.AlarmsLogView) ||
        (signedInUser | userHasPermission : swPermissions.ValueLogView)" class="width100" value="log">
          <ion-label class="ion-text-wrap">{{'Log' | translate}}</ion-label>
        </ion-segment-button>
        <ion-segment-button *ngIf="(signedInUser.userId === 1) && !Location.isIOCommonArea(location) && !Location.isHvacCommonArea(location)" class="width100" value="service">
          <ion-label class="ion-text-wrap">{{'Service' | translate}}</ion-label>
        </ion-segment-button>
      </ion-segment>
  </ion-item>
  <div class="flex-row margin-left150">
    <ion-button *ngIf="(signedInUser | userHasPermission : swPermissions.CardsGuestUpdate) && !Location.isHvacCommonArea(location) && !Location.isIOCommonArea(location) && !Location.isAccessControl(location) && !cardMaxNumberReached" color="light" (click)="newCard()">{{'New card (F9)' | translate}}</ion-button>
    <div *ngIf="cardMaxNumberReached">{{ 'Maximum number of guest cards reached' | translate}}</div>
  </div>
  </div>
  <ng-container *ngIf="activeView === 'view'">
    <app-room-modal-view *ngIf="Location.isGuestRoom(location)" [location]="location"></app-room-modal-view>
    <app-hvac-common-area-modal-view *ngIf="Location.isHvacCommonArea(location)" [location]="location"></app-hvac-common-area-modal-view>
  </ng-container>
  <div *ngIf="activeView === 'settings'">
    <app-room-modal-settings *ngIf="Location.isGuestRoom(location)" [location]="location"></app-room-modal-settings>
    <app-access-control-modal-settings *ngIf="Location.isAccessControl(location)" [location]="location"></app-access-control-modal-settings>
    <app-io-common-area-modal-settings *ngIf="Location.isIOCommonArea(location)" [location]="location"></app-io-common-area-modal-settings>
    <app-hvac-common-area-modal-settings *ngIf="Location.isHvacCommonArea(location)" [location]="location"></app-hvac-common-area-modal-settings>
  </div>
  <div *ngIf="activeView === 'cards'">
    <app-room-modal-cards *ngIf="Location.isGuestRoom(location)" (guestCardsUpdated)="cardsUpdated($event)" [location]="location" ></app-room-modal-cards>
    <app-access-control-modal-cards *ngIf="Location.isAccessControl(location)" [location]="location"></app-access-control-modal-cards>
  </div>
  <div *ngIf="activeView === 'log'">
    <app-room-modal-log *ngIf="Location.isGuestRoom(location)" [controllers]="location.controllers.$values"></app-room-modal-log>
    <app-access-control-modal-log *ngIf="Location.isAccessControl(location)" [controllers]="location.controllers.$values"></app-access-control-modal-log>
    <app-io-common-area-modal-log *ngIf="Location.isIOCommonArea(location)" [controllers]="location.controllers.$values"></app-io-common-area-modal-log>
    <app-hvac-common-area-modal-log *ngIf="Location.isHvacCommonArea(location)" [controllers]="location.controllers.$values"></app-hvac-common-area-modal-log>
  </div>
  <div *ngIf="activeView === 'service'">
    <app-room-modal-service *ngIf="Location.isGuestRoom(location)" [controllers]="location.controllers.$values"></app-room-modal-service>
    <app-access-control-modal-service *ngIf="Location.isAccessControl(location)" [controllers]="location.controllers.$values"></app-access-control-modal-service>
  </div>
</ng-container>
</ion-content>
<ng-template #loading>
  <ion-content>
    <div class="flex-row flex-center height100">
      <ion-spinner name="lines-sharp"></ion-spinner>
    </div>
  </ion-content>
</ng-template>
