import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { AnalyticsToolbarItem } from 'src/app/modules/rooms/components/analytics-toolbar/analytics-toolbar.component';

export interface DisplayConfig {
  name: string,
  // permissionRequired: number;
  data: DisplayPropertyParams[],
  analyticsToolbar: AnalyticsToolbarItem[]
}



export interface DisplayPropertyParams {
  mode: 'singlePropType' | 'status' | 'numericalPropType' | 'custom(fanSpeed)' | 'custom(hvacHeatCoolAuto)' | 'custom(empty)'
  | 'custom(daikinUnitStatus)'  | 'custom(daikinUnitMode)'  | 'custom(daikinUnitFanSpeed)'
  propInfo: {
    activeCondition: 'any' | 'every',
    activeName: string,
    activeIcon: string,
    activeTooltip: string,
    inactiveName: string,
    inactiveIcon: string,
    inactiveTooltip: string,
    codeFrom?: number,
    codeTo?: number,
    statusName?: string,
    sufix?: string
  },
}

@Component({
  selector: 'app-display-property',
  templateUrl: './display-property.component.html',
  styleUrls: ['./display-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayPropertyComponent implements OnInit, OnDestroy {
  @Input() location: Location;
  @Input() propToDisplay: DisplayPropertyParams;
  @Input() displaySize: 'large' | 'minimal' | 'facility';

  Property = Property
  Number = Number;

  propDisplayArray: string[] = []
  pipeSubscription: Subscription;
  updated: Date = new Date();
  targetPropTypeFrom: Property = new Property();
  targetPropTypeTo: Property = new Property();

  constructor(private projectService: ProjectService,
              private cd: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = new Date()
      this.cd.detectChanges();
    })

    this.targetPropTypeFrom.type = this.propToDisplay.propInfo.codeFrom
    this.targetPropTypeTo.type = this.propToDisplay.propInfo.codeTo

    //checking if prop is cleaning status
    if (Property.isCleaningStatus(this.targetPropTypeFrom) && Property.isCleaningStatus(this.targetPropTypeTo)) {
      const target = this.location.controllers.$values.map( cont => cont.controllerProperties.$values.find( prop => Property.isCleaningStatus(prop)))
      if (target[0] && target[0].value == 2) {
        this.propToDisplay.propInfo.activeName = 'Touched'
      }
    }
  }



  ngOnDestroy(): void {
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }
  }

}
