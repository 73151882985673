<div class="flex-column margin-top5 modal-content-container">
  <div class="flex-row-space-evenly width100">
    <ion-list *ngIf="controllers.length > 1" class="margin20 width-150">
      <ion-list-header>{{'Controllers' | translate}}</ion-list-header>
      <ion-item
        button
        *ngFor="let controller of controllers"
        (click)="selectController(controller)"
        [ngClass]="{
          'ion-list-activated': selectedControllers[0].id === controller.id
        }"
      >
        {{ controller.name }}
      </ion-item>
    </ion-list>
      <div
        [ngClass]="[controllers.length > 1 ? 'full-minus-200' : 'width100']"
      >
        <div class="flex-column margin-left10 margin-right10 width100">
          <ion-card-title
            class="flex-row-center-h full-minus-20 padding5 backgroundBlue"
          >
            {{ selectedControllers[0].name }}
          </ion-card-title>

          <div
            style="height: 500px; align-content: center"
            class="flex-row-space-evenly width100"
          >
           <div class="flex-row">
            <div class="flex-col">
              <div class="text-align-center padding5 background-grey">{{'Room' | translate}}</div>
              <div class="flex-row margin25">


              <!--   <div class="flex-col">
                  <div
                    class="background-light-grey flex-center"
                  >
                    <app-number-input
                      [controllers]="selectedControllers"
                      [findPropFunction]="Property.isRoomTemperatureSet"
                    ></app-number-input>
                  </div>
                  <div>
                    <app-number-input
                      class="flex-center"
                      [controllers]="selectedControllers"
                      [findPropFunction]="Property.isHvacRegulation"
                    ></app-number-input>
                  </div>
                </div>
                <div class="flex-col">
                  <div>
                    <app-number-input
                      class="flex-centerr"
                      [controllers]="selectedControllers"
                      [findPropFunction]="Property.isHvacRoomEcoHyst1"
                    ></app-number-input>
                  </div>
                  <div
                    class="flex-center background-light-grey"
                  >
                    <app-number-input
                      [controllers]="selectedControllers"
                      [findPropFunction]="Property.isHvacRoomEcoHyst2"
                    ></app-number-input>
                  </div>
                </div> -->


                <div class="flex-column">
                  <app-room-settings-temps
                   [selectedControllers]="selectedControllers"
                   [hvacBaseSet]="Property.isHVACRoomBaseSet"
                   [hvacEcoHyst1]="Property.isHvacRoomEcoHyst1"
                   [hvacEcoHyst2]="Property.isHvacRoomEcoHyst2"

                   ></app-room-settings-temps>
                  <div class="flex-row-center-v">
                    <app-number-input
                      class="flex-center"
                      [controllers]="selectedControllers"
                      [findPropFunction]="Property.isHvacRegulation"
                    ></app-number-input>
                  </div>
                </div>
              </div>
            </div>

              <ion-radio-group class="margin-left20" [(ngModel)]="fancoilMode" (ionChange)="onFancoilSelect($event)">
                  <ion-list-header>
                   {{'Fancoil' | translate}}
                  </ion-list-header>

                  <ion-item>
                    <ion-radio slot="start" [value]="'0'">{{'Off' | translate}}</ion-radio>
                  </ion-item>

                  <ion-item>
                    <ion-radio slot="start" [value]="'1'">{{'Low' | translate}}</ion-radio>
                  </ion-item>

                  <ion-item>
                    <ion-radio slot="start" [value]="'2'">{{'Medium' | translate}}</ion-radio>
                  </ion-item>

                  <ion-item>
                    <ion-radio slot="start" [value]="'3'">{{'High' | translate}}</ion-radio>
                  </ion-item>

                  <ion-item>
                    <ion-radio slot="start" [value]="'6'">{{'Auto' | translate}}</ion-radio>
                  </ion-item>
              </ion-radio-group>
           </div>
          </div>
        </div>

        <div
          class="
            full-minus-20
            margin-top10 margin-left10 margin-right10
            flex-stretch
          "
        >
          <div class="flex-column">
            <ion-card-title
              class="flex-row-center-h width100 padding5 backgroundBlue"
            >
              {{'Programing' | translate}}
            </ion-card-title>
            <ion-card-content class="width100">
              <div class="flex-row-center-v width100">
                <div class="flex-row">
                  <ion-button *ngIf="toggleHvacExist"
                  class="margin5"
                  color="light"
                  (click)="toggleHvac()"
                  size="small"
                  ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
                  {{'Toggle HVAC' | translate}}</ion-button>

                  <ion-button
                  class="margin5"
                  color="light"
                  (click)="syncHvac()"
                  size="small"
                  ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
                  {{'Settings sync' | translate}}</ion-button>
                  <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCool as hvacHeatCoolProps">
                    <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCoolAuto as hvacHCAutoProps">
                      <!-- HEAT/COOL/AUTO -->
                      <div *ngIf="hvacHCAutoProps.length > 0" class="flex-row">
                        <ion-button class="margin5" size="small"
                          [disabled]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)"
                          [color]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                          (click)="setHvacMode(0)">
                          {{'Heat' |translate}}
                        </ion-button>
                        <ion-button class="margin5" size="small"
                          [disabled]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)"
                          [color]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)?'primary':'light'"
                          (click)="setHvacMode(1)">
                          {{'Cool' |translate}}
                        </ion-button>
                        <ion-button class="margin5"
                          size="small"
                          [disabled]="hvacHCAutoProps | propIsActive"
                          [color]="(hvacHCAutoProps | propIsActive)?'primary':'light'"
                          (click)="setHvacMode(2)">
                          {{'Auto' |translate}}
                        </ion-button>
                      </div>
                      <!-- HEAT/COOL -->
                      <div *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0" class="flex-row">
                        <ion-button class="margin5" size="small"
                          [disabled]="!(hvacHeatCoolProps | propIsActive)"
                          [color]="!(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                          (click)="setHvacMode(0)">
                          {{'Heat' |translate}}
                        </ion-button>
                        <ion-button class="margin5" size="small"
                          [disabled]="(hvacHeatCoolProps | propIsActive)"
                          [color]="(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                          (click)="setHvacMode(1)">
                           {{'Cool' |translate}}
                        </ion-button>
                      </div>
                    </ng-container>
                  </ng-container>
                 <!--  <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCool as isHvacHeatCoolProps">
                    <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCoolAuto as isHvacHeatCoolAutoProps">
                      <ng-container *ngIf="isHvacHeatCoolAutoProps.length > 0">
                        <ion-button
                        size="small"
                        class="margin5"
                        color="light"
                        (click)="toggleHvacModeHeatingCoolingAuto()"
                        ><ion-icon
                          class="margin-right10"
                          name="thermometer-outline"
                        ></ion-icon>
                        {{ "Change airconditioning state" | translate }}</ion-button
                      >
                      </ng-container>
                      <ng-container *ngIf="isHvacHeatCoolAutoProps.length === 0 && isHvacHeatCoolProps.length > 0">
                        <ion-button
                      size="small"
                      class="margin5"
                      color="light"
                      (click)="toggleHvacModeHeatingCooling()"
                      ><ion-icon
                        class="margin-right10"
                        name="thermometer-outline"
                      ></ion-icon>
                      {{ "Change airconditioning state" | translate }}</ion-button
                    >
                      </ng-container>
                    </ng-container>
                  </ng-container> -->

                </div>
                <ion-list class="flex-row">

                  <app-boolean-input
                    [controllers]="controllers"
                    [findPropFunction]="Property.isIgnoreWindow"
                    >{{'Ignore window' | translate}}</app-boolean-input
                  >
                  <app-boolean-input
                    [controllers]="controllers"
                    [findPropFunction]="Property.isIgnoreCardTray"
                    >{{'Ignore card tray' | translate}}</app-boolean-input>
                </ion-list>

                <ion-item lines="none" class="item-background-color">
                  <ion-checkbox
                    (ionChange)="changeControllerPooling($event)"
                    justify="start"
                    labelPlacement="end"
                    class="margin-right5"
                    [checked]="stopControllerPolling"
                  >{{ "Stop controller polling" | translate }}</ion-checkbox>
                </ion-item>
              </div>
            </ion-card-content>
          </div>
        </div>
      </div>
  </div>
</div>
