    <div class="status-container-small flex-row-center-v margin-bottom12 width230">
      <ng-container *ngIf="activeProp; else propNotActive">
        <div [ngClass]="icon" class="icon-background-on font-size19"></div>
        <div class="status-text-on">{{ text | translate }}: {{ activePropTextSufix }}</div>
      </ng-container>
      <ng-template #propNotActive>
        <div
          [ngClass]="icon"
          class="icon-background-off font-size19"
        ></div>
        <div class="status-text-off">{{ text | translate }}: {{ 'Off' | translate}}</div>
      </ng-template>
    </div>

  