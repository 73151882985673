import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IMqttMessage } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { Card } from 'src/app/core/models/card/card.model';
import { Controller } from 'src/app/core/models/controller.model';
import { SoftwarePermissionId } from 'src/app/core/models/permissions/software-permission-id.enum';
import { Location } from 'src/app/core/models/project/location.model';
import { Property } from 'src/app/core/models/project/property.model';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { EditCardModalComponent } from 'src/app/modules/rooms/components/edit-card-modal/edit-card-modal.component';
import { CardsService } from 'src/app/modules/users/services/cards.service';
import { ApiUsersService } from 'src/app/modules/users/services/http/api-users.service';

export type roomModalViewTypes = 'view' | 'settings' | 'cards' | 'log' | 'service';

@Component({
  selector: 'app-room-modal',
  templateUrl: './room-modal.component.html',
  styleUrls: ['./room-modal.component.scss']
})
export class RoomModalComponent implements OnInit, OnDestroy {
  @Input() object: string = '0';
  @Input() subObject: string = '0';
  @Input() zone: string;
  @Input() startingView: roomModalViewTypes;
  @Input() openNewCard = false;
  @Input() locationId: string;

  // project$: Observable<Project>;
  activeView: roomModalViewTypes = 'view';
  updated: Date;
  pipeSubscription: Subscription;
  contSub: Subscription;
  popoverOpened = false;
  // controllers: Controller[];
  // mainController: Controller;
  location: Location;
  Location = Location;
  isAccessControl = Controller.isAccessControl;
  isIOCommonArea = Controller.isIOCommonArea;
  isGuestRoom = Controller.isGuestRoom;
  isHvacCommonArea = Controller.isHvacCommonArea;

  signedInUser: User;
  swPermissions = SoftwarePermissionId;

  guestCards: Card[] = [];
  cardMaxNumberReached = false;
  controllerSubscription: Subscription;

  roomIsJoined = false;

  constructor(
              private projectService: ProjectService,
              private cardsService: CardsService,
              private apiUsersService: ApiUsersService,
              private currentUserStoreService: CurrentUserStoreService,
              private modalController: ModalController,
              private apiProjectService: ApiProjectService
              ) { }

  ngOnInit(): void {
    this.cardsService.setCardInMaking(true);
    this.signedInUser = Object.assign(new User(), this.currentUserStoreService.getUser());
    this.getFilteredLocationById();
    if (this.startingView && !Location.isGuestRoom(this.location) &&
     !User.hasAnyOfRequiredPermissions(this.signedInUser, [this.swPermissions.LocationSettings])) {
      this.activeView = 'cards';
     }else if (this.startingView){
      this.activeView = this.startingView;
     }

    this.pipeSubscription = this.projectService.updatedPipe$.subscribe( value => {
      this.updated = value;
    })

    this.controllerSubscription = this.projectService.singleControllerMessage$.subscribe( value => {
      this.updateControllerByMqttMsg(value)
    })

  }


  getFilteredLocationById() {
    if (this.apiProjectService.filteredFatLocationsIsReady$.value) {
      this.location = this.projectService.getFilteredLocationById(this.locationId)
      if (!this.location) {
        //failsafe for retro mode
        this.getLocationByApi()
      } else {
        if (this.openNewCard) {
          this.newCard();
        }
        this.getCardsByLocation()
        this.checkIsLocationJoined()
      }
    } else {
      this.getLocationByApi()
    }
  }

  getLocationByApi() {
    this.apiProjectService.getLocationById(this.locationId)
    .subscribe((location: Location)=> {
      this.location = location;
      if (this.openNewCard) {
        this.newCard();
      }
      this.getCardsByLocation()
      this.checkIsLocationJoined()
    })
  }

  checkIsLocationJoined() {
    if (this.location.isJoined) {
      this.roomIsJoined = true;
      this.getJoinedControllers();
    }
  }

  getJoinedControllers() {
    // TODO NAPRAVIT JOIN
    // const locationIds = this.mainController.controllerSettings.$values.find( setting => setting.name.toLowerCase() == 'joinedrooms').value.split(',')
    // locationIds.forEach ( loc => {
    //   loc = loc + '/0';
    //   if (this.mainController.designation !== loc) {
    //     const target = this.projectService.getControllersByDesignation(loc.split('/')[0], loc.split('/')[1], loc.split('/')[2])
    //     const mainControler = Controller.getMainController(target);
    //     if (mainControler.isJoined) {
    //       this.controllers.push(...target);
    //     }
    //   }
    // })
  }

  updateControllerByMqttMsg(message: IMqttMessage) {
    if (this.location != undefined && message !== null) {
      const payloadIndex = message.payload.toString().lastIndexOf('|');
      const payload = message.payload.toString().slice(payloadIndex + 1);

      // example topic cli/driver/1/0/0/101/0/hvac/temp/set/room
      const driverPref = message.topic.split('/')[1] + '/' + message.topic.split('/')[2];
      const obj = message.topic.split('/')[3];
      const subObj = message.topic.split('/')[4];
      const zoneId = message.topic.split('/')[5];
      const subZoneId = message.topic.split('/')[6];
      const propTopic = this.getPropTopic(message.topic);
      let targetProp: Property;

      if (propTopic) {
         this.location.controllers.$values.find( cont => {
            if (cont.driverPrefix.toLowerCase() === driverPref.toLowerCase() && cont.object === obj && cont.subObject === subObj && cont.zone === zoneId && cont.subZone === subZoneId) {
              targetProp = cont.controllerProperties.$values.find((prop)=> {
                return prop.mqttTopic === propTopic
              })
              return true;
            }
          })

      }

      if (targetProp) {
        targetProp.value = payload;
      }
    }
  }

  tabClicked(card: roomModalViewTypes) {
    this.activeView = card;
    if (this.activeView !== 'cards') {
      this.openNewCard = false;
    }
  }

  getPropTopic(topic: string): string { // example input: dev/irooms/1/0/0/101/1/hvac/temp/meas/room,
    const topicArray = topic.split('/');
    let propTopic = '';
    for (let index = 7; index < topicArray.length; index++) {
      propTopic = propTopic + topicArray[index];
      if (index < topicArray.length-1) {
        propTopic = propTopic + '/';
      }
    }
    return propTopic;  // example output : hvac/temp/meas/room
  }

  async newCard() {
      const modal = await this.modalController.create({
        component: EditCardModalComponent,
        cssClass: 'cards-popover',
        backdropDismiss: true,
        showBackdrop: true,
        componentProps:  {
          location: this.location
          }
      });
      return await modal.present();

  }


  @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
      if (event.key === 'F9') {
        if (!this.popoverOpened) {
          this.newCard();
        }
      }
    }

    getCardsByLocation() {
      //TODO MAYBE CHANGE
      this.apiUsersService.getCardsByLocation(this.location.locationId).subscribe( (value: Card[]) => {
        this.guestCards = value.filter( card => card.type === 1 && !card.isBackup);

        // if we already have max number of guest cards we need to say that if someone is trying to create a new one
        if (Controller.isGuestRoom(this.location.controllers.$values[0]) && this.guestCards.length >= Controller.getMaxGuestCardsNumber(this.location.controllers.$values[0]) - 1) {
          this.cardMaxNumberReached = true;
        }
      })
    }


    cardsUpdated(updatedGuestCards: Card[]) { // event emiter from child component app-room-modal-cards
      this.guestCards = updatedGuestCards;
      // if we already have max number of guest cards we need to say that if someone is trying to create a new one
      if (Controller.isGuestRoom(this.location.controllers.$values[0]) && this.guestCards.length >= Controller.getMaxGuestCardsNumber(this.location.controllers.$values[0]) - 1) {
        this.cardMaxNumberReached = true;
      } else {
        this.cardMaxNumberReached = false;
      }
    }

  ngOnDestroy(): void {
    if(this.pipeSubscription) {
      this.pipeSubscription.unsubscribe();
    }
    if(this.contSub) {
      this.contSub.unsubscribe();
    }
    if (this.controllerSubscription) {
      this.controllerSubscription.unsubscribe();
    }
    this.cardsService.setCardInMaking(false);
  }
}
