<div class="flex-row-space-evenly margin-top30 modal-content-container">
  <div class="width95 flex-row">
    <div class="flex-column width8">
      <ion-list *ngIf="controllers.length > 1" class="margin20 width-150">
        <ion-list-header>{{ "Controllers" | translate }}</ion-list-header>
        <ion-item
          button
          *ngFor="let controller of controllers"
          (click)="selectController(controller)"
          [ngClass]="{
            'ion-list-activated': selectedControllers[0].id === controller.id
          }"
        >
          {{ controller.name }}
        </ion-item>
      </ion-list>
    </div>
    <ng-container *ngIf="renderNumberInputs">

    <div class="flex-column width23">
      <div class="margin-bottom30 uppercase">{{ "Room" | translate }}</div>

       <ng-container *ngFor="let propData of (settings$ | async).room  | sortByAdminProps as roomSettings; let i = index">
          <ng-container
  *ngIf="selectedControllers | findPropertyByCode : propData.codeFrom: propData.codeTo  as settingsRoomProps"
>
<ng-container *ngIf="settingsRoomProps.length > 0">
  <div *ngIf="i>0 && roomSettings[i-1].adminProp=== false &&
    roomSettings[i].adminProp=== true &&
     logedInUser.userId === 1"
    class="margin-bottom15 margin-top10 uppercase">{{ "Admin" | translate }}
  </div>
  <ng-container *ngIf="logedInUser.userId === 1 || propData.adminProp === false">
        <app-number-input-code
         [controllers]="selectedControllers"
          [propData]="propData"
        ></app-number-input-code>
    </ng-container>
      </ng-container>
      </ng-container>
    </ng-container>


      <app-room-temp-info [selectedControllers]="selectedControllers"
        [location]="'room'"
      ></app-room-temp-info>
    </div>

    <div *ngIf="bathroomHasTemps" class="flex-column width24">
      <div class="margin-bottom30 uppercase">{{ "Bathroom" | translate }}</div>
      <ng-container *ngFor="let propData of (settings$ | async).bathroom | sortByAdminProps as bathroomSettings; let i = index">
        <ng-container
        *ngIf="selectedControllers | findPropertyByCode : propData.codeFrom: propData.codeTo  as settingsBathroomProps"
      >
      <ng-container *ngIf="settingsBathroomProps.length > 0">
        <div *ngIf="i>0 && bathroomSettings[i-1].adminProp=== false &&
          bathroomSettings[i].adminProp=== true &&
          logedInUser.userId === 1"
         class="margin-bottom15 margin-top10 uppercase">{{ "Admin" | translate }}
       </div>
       <ng-container *ngIf="logedInUser.userId === 1 || propData.adminProp === false">
        <app-number-input-code
         [controllers]="selectedControllers"
          [propData]="propData"
        ></app-number-input-code>
      </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
      <div style="height:54px"></div><!-- SPACER -->
      <app-room-temp-info *ngIf="selectedControllers | roomHaveBathroom" [selectedControllers]="selectedControllers"
        [location]="'bathroom'"
      ></app-room-temp-info>
    </div>
  </ng-container>
  <div class="width25" *ngIf="!renderNumberInputs"></div><!-- SPACER. Kees spacing while rerendering components -->
  <div class="width25" *ngIf="!renderNumberInputs"></div>

    <div class="flex-column width45">
      <div class="margin-bottom30 uppercase width100">
        {{ "Programming" | translate }}
      </div>
      <div class="flex-column programming-box">
        <ion-button
          size="small"
          class="margin5 width45"
          color="light"
          style="letter-spacing: 0.1px;"
          (click)="openPopoverToSelectPreset($event)"
          ><ion-icon class="margin-right10" name="settings-sharp"></ion-icon>
          {{ "Reset to default settings" | translate }}</ion-button>

        <ng-container
          *ngIf="controllers | findProperty: Property.isRoomArmed as roomArmedProps"
        >
          <ion-button
            *ngIf="roomArmedProps?.length > 0"
            class="margin5 width45"
            color="light"
            (click)="armRoom()"
            size="small"
            ><ion-icon class="margin-right10" name="shield-half-outline">
            </ion-icon
            >{{'Arm room' | translate}} (alarm)</ion-button
          >
        </ng-container>
        <ng-container
          *ngIf="
            controllers
              | findProperty
                : Property.isMinibarWasOpenedStatus as isMinibarWasOpenedProps
          "
        >
          <ion-button
            *ngIf="isMinibarWasOpenedProps?.length > 0"
            size="small"
            class="margin5 width45"
            color="light"
            (click)="resetMinibar()"
            ><ion-icon class="margin-right10" name="wine-outline"></ion-icon
            >{{ "Reset minibar" | translate }}</ion-button
          >
        </ng-container>

        <ion-button
        class="margin-left5 margin-right5 margin-top5 margin-bottom10 width45"
        color="light"
        (click)="syncHvac()"
        size="small"
        ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
        {{'Settings sync' | translate}}</ion-button>

          <div style="width: 250px;">
                <app-boolean-input
                  *ngIf="
                controllers | propExistInControllers : Property.isPanelBlocked
              "
                  [controllers]="controllers"
                  [findPropFunction]="Property.isPanelBlocked"
                  >{{ "Block keyboard" | translate }}</app-boolean-input
                >
                <app-boolean-input
                  *ngIf="
                controllers | propExistInControllers : Property.isIgnoreWindow
              "
                  [controllers]="controllers"
                  [findPropFunction]="Property.isIgnoreWindow"
                  >{{ "Ignore window" | translate }}</app-boolean-input
                >
                <app-boolean-input
                  *ngIf="
                controllers | propExistInControllers : Property.isIgnoreCardTray
              "
                  [controllers]="controllers"
                  [findPropFunction]="Property.isIgnoreCardTray"
                  >{{ "Ignore card tray" | translate }}</app-boolean-input
                >
                <ion-item lines="none" class="item-background-color">
                  <ion-checkbox
                    (ionChange)="changeResetDefaultPropertiesOnCheckout($event)"
                    justify="start"
                    labelPlacement="end"
                        [checked]="location.restoreDefaultOnCheckout"

                    ><span class="ion-text-wrap">{{
                  "Reset to default settings on checkout" | translate
                }}</span>
                </ion-checkbox
              >
                </ion-item>

                <ion-item lines="none" class="item-background-color">
                  <ion-checkbox
                    (ionChange)="changeControllerPooling($event)"
                    justify="start"
                    labelPlacement="end"
                    class="margin-right5"
                    [checked]="stopControllerPolling"
                  ><span class="ion-text-wrap">
                {{ "Stop controller polling" | translate }}</span></ion-checkbox
              >
            </ion-item>

          </div>
          <div style="width: 100%; margin-left: 15px;">
            <ng-container *ngIf="controllers | findProperty:  Property.isHvacHeatCool as hvacHeatCoolProps">
              <ng-container *ngIf="controllers | findProperty: Property.isHvacHeatCoolAuto as hvacHCAutoProps">
                <!-- HEAT/COOL/AUTO -->
                <div *ngIf="hvacHCAutoProps.length > 0" class="flex-row">
                  <ion-button class="margin5 width13" size="small"
                    [disabled]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)"
                    [color]="!(hvacHCAutoProps | propIsActive) && !(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                    (click)="setHvacMode(0)">
                    {{'Heat' | translate}}
                  </ion-button>
                  <ion-button class="margin-bottom5 margin-top5 width13" style="margin-left: 2%;" size="small"
                    [disabled]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)"
                    [color]="!(hvacHCAutoProps | propIsActive) && (hvacHeatCoolProps | propIsActive)?'primary':'light'"
                    (click)="setHvacMode(1)">
                    {{'Cool' | translate}}
                  </ion-button>
                  <ion-button class="margin-bottom5 margin-top5 width13" style="margin-left: 3%;"
                    size="small"
                    [disabled]="hvacHCAutoProps | propIsActive"
                    [color]="(hvacHCAutoProps | propIsActive)?'primary':'light'"
                    (click)="setHvacMode(2)">
                    {{'Auto' | translate}}
                  </ion-button>
                </div>
                <!-- HEAT/COOL -->
                <div *ngIf="hvacHCAutoProps.length === 0 && hvacHeatCoolProps.length > 0" class="flex-row">
                  <ion-button class="margin5 width21" size="small"
                    [disabled]="!(hvacHeatCoolProps | propIsActive)"
                    [color]="!(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                    (click)="setHvacMode(0)">
                    {{'Heat' | translate}}
                  </ion-button>
                  <ion-button class="margin-bottom5 margin-top5 width21" style="margin-left: 2%;" size="small"
                    [disabled]="(hvacHeatCoolProps | propIsActive)"
                    [color]="(hvacHeatCoolProps | propIsActive)?'primary':'light'"
                    (click)="setHvacMode(1)">
                     {{'Cool' | translate}}
                  </ion-button>
                </div>
              </ng-container>
            </ng-container>

            <ion-button *ngIf="toggleHvacExist"
            class="margin5 width45"
            color="light"
            (click)="toggleHvac()"
            size="small"
            ><ion-icon class="margin-right10" name="sync-outline"></ion-icon>
            {{'Toggle HVAC' | translate}}</ion-button>

            <ng-container
              *ngIf="
                controllers
                  | findProperty
                    : Property.isBurglaryDetectedDoor as burglaryDetectedProps
              "
            >
              <ion-item
                *ngIf="burglaryDetectedProps.length > 0"
                lines="none"
                class="item-background-color width45"
              >
                <ion-checkbox
                  (click)="
                    changeIgnoreBurglary($event, burglaryDetectedProps)
                  "
                  justify="start"
                  labelPlacement="end"
                  [checked]="
                    ignorePublishingList
                      | includes : burglaryDetectedProps[0].id
                  "
                  [disabled]="loadingPublishing"
                ><span class="">
                  {{ "Ignore burglary by door" | translate }}
                </span>
                </ion-checkbox
                >
              </ion-item>
            </ng-container>
            <ng-container
              *ngIf="
                controllers
                  | findProperty
                    : Property.isBurglaryDetectedWindow as burglaryDetectedWindowProps
              "
            >
              <ion-item
                *ngIf="burglaryDetectedWindowProps.length > 0"
                lines="none"
                class="item-background-color width45"
              >
                <ion-checkbox
                  (click)="
                    changeIgnoreBurglary($event, burglaryDetectedWindowProps)
                  "
                  justify="start"
                  labelPlacement="end"
                  [checked]="
                    ignorePublishingList
                      | includes : burglaryDetectedWindowProps[0].id
                  "
                  [disabled]="loadingPublishing"
                >
                <span class="">
                  {{ "Ignore burglary by window" | translate }}</span>
                  </ion-checkbox
                >
              </ion-item>
            </ng-container>
            <ng-container
              *ngIf="
                controllers
                  | findProperty
                    : Property.isBurglaryDetectedByPIR as burglaryDetectedByPIR
              "
            >
              <ion-item
                *ngIf="burglaryDetectedByPIR.length > 0"
                lines="none"
                class="item-background-color width45"
              >
                <ion-checkbox
                  (click)="
                    changeIgnoreBurglary($event, burglaryDetectedByPIR)
                  "
                  justify="start"
                  labelPlacement="end"
                  [checked]="
                    ignorePublishingList
                      | includes : burglaryDetectedByPIR[0].id
                  "
                  [disabled]="loadingPublishing"
                ><span class="ion-text-wrap">
                  {{ "Ignore burglary by pir" | translate }}</span></ion-checkbox
                >
                  </ion-item>
            </ng-container>
          </div>

        <div style="width: 50%" class="margin-left15">

          <!-- <app-boolean-input
            [controllers]="controllers"
            [findPropFunction]="Property.isBathroomRadiatorByRented"
            >{{ "Bathroom radiator by rented" | translate }}</app-boolean-input> -->
            <div *ngIf="controllers | propExistInControllers : Property.isBathroomRadiatorByRented">
              <div class="font-size16 margin-bottom15" style="margin-left: 8px; margin-top: 5px; font-family: sans-serif;">Bathroom radiator: </div>
                <div *ngFor="let option of optionsBathroomRadiator" class="margin-top5">
                  <div class="flex-column" style="margin-left: 11px; font-family: sans-serif;">
                  <label class="font-size16 margin-bottom10 clickable">
                    <input
                      style="transform: scale(1.3);"
                      name="options"
                      class="margin-right10 clickable"
                      type="radio"
                      [(ngModel)]="selectedOptionBathroom"
                      [value]="option"
                      [disabled]="reqInProgress"
                      (change)="onSelectBathroomRadiatorBy(option)">{{ option }}</label>
                </div>
              </div>
            </div>

          <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isBathroomRadiatorForcedOn"
            [controllers]="controllers"
            [findPropFunction]="Property.isBathroomRadiatorForcedOn"
            >{{ "Bathroom radiator force on" | translate }}</app-boolean-input>
          <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isBathroomRadiatorInCooling"
            [controllers]="controllers"
            [findPropFunction]="Property.isBathroomRadiatorInCooling"
            >{{ "Bathroom radiator in cooling" | translate }}</app-boolean-input>

          <!-- <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isRadiatorByRented"
            [controllers]="controllers"
            [findPropFunction]="Property.isRadiatorByRented"
            >{{ "Room radiator by rented" | translate }}</app-boolean-input> -->
          <div *ngIf="controllers | propExistInControllers : Property.isRadiatorByRented">
            <div class="font-size16 margin-bottom15" style="margin-left: 8px; margin-top: 5px; font-family: sans-serif;">Room radiator: </div>
              <div *ngFor="let option of optionsRoomRadiator" class="margin-top5">
                <div class="flex-column" style="margin-left: 11px; font-family: sans-serif;">
                <label class="font-size16 margin-bottom10 clickable">
                  <input
                    style="transform: scale(1.3);"
                    name="options"
                    class="margin-right10 clickable"
                    type="radio"
                    [(ngModel)]="selectedOptionRoom"
                    [value]="option"
                    [disabled]="reqInProgress"
                    (change)="onSelectRoomRadiatorBy(option)">{{ option }}</label>
              </div>
            </div>
          </div>

          <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isRadiatorForcedOn"
            [controllers]="controllers"
            [findPropFunction]="Property.isRadiatorForcedOn"
            >{{ "Room radiator force on" | translate }}</app-boolean-input>
          <app-boolean-input
            *ngIf="controllers | propExistInControllers : Property.isRadiatorInCooling"
            [controllers]="controllers"
            [findPropFunction]="Property.isRadiatorInCooling"
            >{{ "Room radiator in cooling" | translate }}</app-boolean-input>
      </div>
      </div>
    </div>
  </div>
</div>
