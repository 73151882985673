import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Controller } from 'src/app/core/models/controller.model';
import { CardOnHolderService } from 'src/app/core/services/card-on-holder.service';

@Component({
  selector: 'app-modal-confirmation',
  templateUrl: './modal-confirmation.component.html',
  styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit, OnDestroy {
  @Input() type: string;
  @Input() content: any;
  @Input() loading = false;
  @Input() burgRooms: Controller[] = [];
  @Output() decision: EventEmitter<boolean> = new EventEmitter();
  cardOnHolder = false;
  cardHolderSubscription: Subscription;

  constructor(private cardOnHolderService: CardOnHolderService,
    ) { }

  ngOnInit(): void {
    this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    this.cardHolderSubscription = this.cardOnHolderService.cardOnHolderChanged.subscribe(()=> {
      this.cardOnHolder = this.cardOnHolderService.getCardOnHolder();
    })
  }

  decisionClick(choice: boolean) {
    this.decision.emit(choice);
  }

  ngOnDestroy() {
    if (this.cardHolderSubscription) {
      this.cardHolderSubscription.unsubscribe();
    }
  }
}
