import { Component, Input, OnInit } from '@angular/core';
import { Controller } from 'src/app/core/models/controller.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Setting } from 'src/app/core/models/project/setting.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ApiSettingsService, DefaultsPreset } from 'src/app/modules/settings/services/http/api-settings.service';
import { SettingsService } from 'src/app/modules/settings/services/settings.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiLocationGroupsService, LocationGroup } from '../../services/api-location-groups.service';
import { LocationGroupType } from 'src/app/core/models/location-group-type.enum';
import { LocationPresetCrossRef } from 'src/app/core/models/cross-ref.model';
import { ButtonsSelectComponent } from '../buttons-select/buttons-select.component';
import { PopoverController } from '@ionic/angular';
import { User } from 'src/app/core/models/user/user.model';
import { CurrentUserStoreService } from 'src/app/core/services/current-user-store.service';
import { Location } from 'src/app/core/models/project/location.model';

export interface SettingsPropData  {
    name: string,
    codeFrom: number,
    codeTo: number,
    codeFromConstraintA?: number,
    codeToConstraintA?: number,
    codeFromConstraintB?: number,
    codeToConstraintB?: number,
    adminProp: boolean
}

@Component({
  selector: 'app-room-modal-settings',
  templateUrl: './room-modal-settings.component.html',
  styleUrls: ['./room-modal-settings.component.scss']
})
export class RoomModalSettingsComponent implements OnInit {
  @Input() location: Location;
  controllers: Controller[];
  isAccessControl = Controller.isAccessControl;
  selectedControllers: Controller[] = []; // only one controller should be selected at a time [0]
  intervalForDoorsActive: boolean;
  defaultSettings: DefaultsPreset[];
  defaultPresetsSubscription: Subscription;
  stopControllerPolling = false;
  controllerPollingSubscription: Subscription;

  locationCrossRef: LocationPresetCrossRef[] =[];
  defaultPresets: DefaultsPreset[] = [];
  hvacModeLocationGroups: LocationGroup[] = [];
  props: Property[] = [];

  Property = Property;
  renderNumberInputs = true;
  logedInUser: User;
  settings$ = this.settingsService.getRoomModalPropSettings();
  bathroomHasTemps: boolean;
  settingsSubscription: Subscription;

  toggleHvacExist = false;

  ignorePublishingList = [];
  loadingPublishing = false;
  optionsRoomRadiator = ['active by rented', 'active by presence'];
  optionsBathroomRadiator = ['active by rented ', 'active by presence '];
  selectedOptionRoom: string;
  selectedOptionBathroom: string;
  reqInProgress = false;

  constructor(
    private settingsService: SettingsService,
    private apiProjectService: ApiProjectService,
    private projectService: ProjectService,
    private apiSettingsService: ApiSettingsService,
    private apiLocationGroupsService: ApiLocationGroupsService,
    private popoverController: PopoverController,
    private currentUserStoreService: CurrentUserStoreService
    ) {}

  ngOnInit(): void {
    this.controllers = this.location.controllers.$values;
    this.selectController(this.controllers[0]);
    this.apiSettingsService.getDefaultPresets().subscribe();
    this.defaultPresetsSubscription = this.settingsService.getDefaultPresets().subscribe( value => {
      this.defaultSettings = value;
    })
    this.isControllerPolled();
    this.getLocationPreseCrossRef();
    this.getDefaultPresets();
    this.getHvacModeLocationGroups();
    this.logedInUser = this.currentUserStoreService.getUser()
    this.toggleHvacExist = this.isPropertyInController(Property.isHvacToggle)
    this.checkIfBathroomHasTemps();
    this.apiProjectService.getNotPublishingList().subscribe((list)=> {
      this.ignorePublishingList = list;
      this.loadingPublishing = false;
    });
    this.getRoomRentedValue();
    this.getBathroomRentedValue();
  }

  checkIfBathroomHasTemps() {
    this.settingsSubscription = this.settings$.subscribe((settings) => {
      settings.bathroom.forEach((setting) => {
          this.props = this.selectedControllers.reduce((allFilteredProps, controller)=> {
          const filteredPropsOnController = controller.controllerProperties.$values.filter((prop: Property) => {
            return (prop.type >= Number(setting.codeFrom) && prop.type <= Number(setting.codeTo))
          });
          allFilteredProps = [...allFilteredProps, ...filteredPropsOnController]
          return allFilteredProps;
        },[]);
      })
    })
    if(this.props.length > 0){
      this.bathroomHasTemps = true;
    }else{
      this.bathroomHasTemps = false;
    }
  }

  isControllerPolled() {
    this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    this.apiProjectService.getNotPolledControllers().subscribe( value => {
      value.forEach( cont => {
        if (cont.object === this.controllers[0].object && cont.subObject === this.controllers[0].subObject
          && cont.zone === this.controllers[0].zone && cont.subZone === this.controllers[0].subZone) {
          this.stopControllerPolling = true;
        }
      })
    })
    this.controllerPollingSubscription = this.projectService.notPolledControllersChanged.subscribe( () => {
      this.stopControllerPolling = this.projectService.isControllerPolled(this.controllers[0]);
    })
  }

  async changeResetDefaultPropertiesOnCheckout(event: any) {
    if (event.target.checked) {
      this.location.restoreDefaultOnCheckout = true;
      this.apiProjectService.updateLocation(this.location).subscribe();
    } else {
      this.location.restoreDefaultOnCheckout = false;
      this.apiProjectService.updateLocation(this.location).subscribe();
    }
  }

  getLocationPreseCrossRef() {
    this.apiSettingsService.getLocationPresetCrossReferences().subscribe( value => {
      this.locationCrossRef = value;
    })
  }

  getDefaultPresets() {
    this.apiSettingsService.getDefaultPresets().subscribe( value => {
      this.defaultPresets = value;
    })
  }

  getHvacModeLocationGroups() {
    this.apiLocationGroupsService.getLocationGroupsByType(LocationGroupType.HvacModeGroup).subscribe( value => {
      this.hvacModeLocationGroups = value;
    })
  }

  armRoom() {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isRoomArmed);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    const value = 1;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  resetMinibar() {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isMinibarWasOpenedStatus);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    const value = 1;
    this.apiProjectService
      .changeProperty(designation, propertyId, value)
      .subscribe();
  }

  setHvacMode(hvacModeNewValue: 0 | 1 | 2) {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacHeatCool);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, hvacModeNewValue)
      .subscribe();
  }

  async openPopoverToSelectPreset(ev) {
    const popover = await this.popoverController.create({
      component: ButtonsSelectComponent,
      cssClass: 'button-select',
      showBackdrop: false,
      event: ev,
      componentProps: {
        locationCrossRef: this.locationCrossRef,
        defaultPresets: this.defaultPresets,
        hvacModeLocationGroups: this.hvacModeLocationGroups,
        controller: this.selectedControllers[0]
      }
    });
    await popover.present();

    popover.onDidDismiss().then( (value) => {
      if (value.data) {
        this.apiSettingsService.activateDefaultPreset(value.data, this.selectedControllers[0].designation, true).subscribe()
      }
    })

}

  selectController(controller: Controller) {
    this.renderNumberInputs = false;
    this.selectedControllers[0] = controller;
    this.selectedControllers = this.selectedControllers.slice();
    setTimeout(() => { // render is reset to reinit child components and restart ngoinit and ngodestroy of child components
      this.renderNumberInputs = true;
    }, 0);

  }


  isPropertyInController(target: any) {
    let property: Property;
    const active: boolean = this.controllers.some(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(target);
        return property;
      }
    );

    return active
  }

  isSettingInController(target: any) {
    let setting: Setting;
    const active: Controller = this.controllers.find(
      (controller: Controller) => {
        setting = controller.controllerSettings.$values.find(target);
        return setting;
      }
    );

    if(setting && setting.value === '1') {
      return false;
    } else {
      return active
    }
  }

  syncHvac() {
    this.apiProjectService.syncHvacSettings(this.selectedControllers[0].id).subscribe()
  }

  toggleHvac() {
    let property: Property;
    const parentController: Controller = this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isHvacToggle);
        return property;
      }
    );
    const designation = parentController.designation;
    const propertyId = property.id;
    this.apiProjectService
      .changeProperty(designation, propertyId, 1)
      .subscribe();
  }

  changeControllerPooling(event: any) {
    const contToSend = structuredClone(this.controllers[0])
    if (event.target.checked) {
      this.apiProjectService.stopPolling(contToSend).subscribe()
    } else {
      this.apiProjectService.resumePolling(contToSend).subscribe()
    }
  }

  changeIgnoreBurglary(event: any, properties: Property[]) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
    event.stopPropagation();
    this.loadingPublishing = true;
    if (!event.target.checked) {
      this.apiProjectService.stopPublishing(properties[0].id).subscribe(this.afterChangePublishingObserver())
    } else {
      this.apiProjectService.resumePublishing(properties[0].id).subscribe(this.afterChangePublishingObserver())
    }
  }

  afterChangePublishingObserver(){
    return {next: ()=> {
      this.apiProjectService.getNotPublishingList().subscribe({
        next: (list)=> {
          this.ignorePublishingList = list;
          this.loadingPublishing = false;
        },
        error: ()=> {
          this.loadingPublishing = false;
        }})
    },
    error: ()=> {
      this.loadingPublishing = false;
    }}
    }

  onSelectRoomRadiatorBy(option: string){
    this.reqInProgress = true;
    const designation = Controller.getMainController(this.controllers).designation;
    let property: Property;
    this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isRadiatorByRented);
        return property;
      }
    );
    const propertyId = property.id;
    if(option === 'active by rented'){
      this.apiProjectService.changeProperty(designation, propertyId, 1).subscribe(() => this.reqInProgress = false);
    }else if(option === 'active by presence'){
      this.apiProjectService.changeProperty(designation, propertyId, 0).subscribe(() => this.reqInProgress = false);
    }
  }

  onSelectBathroomRadiatorBy(option: string){
    this.reqInProgress = true;
    const designation = Controller.getMainController(this.controllers).designation;
    let property: Property;
    this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isBathroomRadiatorByRented);
        return property;
      }
    );
    const propertyId = property.id;
    if(option === 'active by rented '){
      this.apiProjectService.changeProperty(designation, propertyId, 1).subscribe(() => this.reqInProgress = false);
    }else if(option === 'active by presence '){
      this.apiProjectService.changeProperty(designation, propertyId, 0).subscribe(() => this.reqInProgress = false);
    }
  }

  getRoomRentedValue(){
    let property: Property;
    this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isRadiatorByRented);
        return property;
      }
    );
    if(property !== undefined && property !== null){
      if(property.value === '0'){
        this.selectedOptionRoom = 'active by presence'
      }else if(property.value === '1'){
        this.selectedOptionRoom = 'active by rented'
      }
    }
  }

  getBathroomRentedValue(){
    let property: Property;
    this.controllers.find(
      (controller: Controller) => {
        property = controller.controllerProperties.$values.find(Property.isBathroomRadiatorByRented);
        return property;
      }
    );
    if(property !== undefined && Property !== null){
      if(property.value === '0'){
        this.selectedOptionBathroom = 'active by presence '
      }else if(property.value === '1'){
        this.selectedOptionBathroom = 'active by rented '
      }
    }
  }

  onDestroy(){
    if (this.defaultPresetsSubscription) {
      this.defaultPresetsSubscription.unsubscribe();
    }
    if (this.controllerPollingSubscription) {
      this.controllerPollingSubscription.unsubscribe();
    }
    if (this.settingsSubscription) {
      this.settingsSubscription.unsubscribe();
    }

  }


}
